import capitalize from "lodash/capitalize";
import moment from "moment";
import React, { memo } from "react";
import {
  TableFilterInputText,
  TableFilterRangeTimeInput,
} from "../../../components/table-filter-inputs";
import { getEnv } from "../../../configs";
import { getLocaleKey, translate } from "../../../languages";
import { NumberUtils, ObjectUtils, Table } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { useSelector } from "../../../store";
import { withTransactionWraper } from "../wraper";

export const AirdropPage = withTransactionWraper(
  memo(
    () => {
      //const last1MothDate = Date.now() - 1000 * 60 * 60 * 24 * 30;

      const startOfMonth = moment().startOf("month").format("YYYY/MM/DD HH:mm:ss");
      const endOfDay = moment().endOf("day").format("YYYY/MM/DD HH:mm:ss");

      const coinsState = useSelector((state) => state.coins);
      const coinsOptions = ObjectUtils.getIn(coinsState, "data", [], (arr) =>
        arr.map((item: any) => ({
          label: item.name,
          value: item.coinId,
        }))
      );
      // const UsdId = 2;
      // const ButId = 5;
      // const DemoId = 4;
      const Gp2Id = 5;

      return (
        <div className="TransactionList">
          <Table
            hasOrderColumn
            itemPerPages={10}
            filters={[
              {
                name: translate("time"),
                key: "created",
                input: (e) => (
                  <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />
                ),
                defaultValue: {
                  fromDate: startOfMonth, //new Date(last1MothDate),
                  toDate: endOfDay, //new Date(),
                },
              },
              {
                name: "Email",
                key: "email",
                input: TableFilterInputText,
              },
              // {
              //     name: "Amount",
              //     key: "amount",
              //     input: TableFilterInputNumber,
              // },
            ]}
            structure={[
              {
                name: "Coin",
                key: "coinId",
                className: "coin",
                render: (item) => {
                  const coin = coinsOptions.find((v: any) => v.value === item.coinId);
                  console.log("🚀 ~ file: index.tsx ~ line 70 ~ coin", coin);
                  let coinAvatar;
                  switch (coin?.value) {
                    case 3: {
                      coinAvatar = "usdt.png";
                      break;
                    }
                    case 6: {
                      coinAvatar = "usd.png";
                      break;
                    }
                    case 8: {
                      coinAvatar = "vlt.png";
                      break;
                    }
                  }
                  return (
                    <>
                      <img
                        className="coin-avatar"
                        src={`/assets/images/coins/${coinAvatar}`}
                        alt=""
                      />
                      <span className="coin-label">{ObjectUtils.getIn(coin, "label", "--")}</span>
                    </>
                  );
                },
              },
              {
                name: "Time",
                key: "created",
                render: (item) => {
                  const date = new Date(item.created);
                  return (
                    <span>
                      {date.toLocaleDateString(getLocaleKey())}{" "}
                      {date.toLocaleTimeString(getLocaleKey())}
                    </span>
                  );
                },
              },
              {
                name: "Email",
                key: "email",
                className: "email",
              },
              {
                name: "Amount",
                key: "value",
                render: (item) => {
                  const { balanceAfter, balanceBefore } = item;
                  let isIncrease = false;
                  if (balanceAfter > balanceBefore) isIncrease = true;
                  return (
                    <span className={isIncrease ? "textSuccess" : "textDanger"}>
                      {isIncrease ? "+" : "-"}
                      {NumberUtils.toFormatNumber(+item?.value, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
                    </span>
                  );
                },
              },
              {
                name: "Type",
                key: "transactionTypeId",
                render: (item) =>
                  ObjectUtils.getIn(
                    capitalize(item.transactionType.name.replace(/_/g, " ")),
                    "",
                    "--"
                  ),
                //render: (item) => ObjectUtils.getIn(item, 'transactionType.name', '--'),
              },
              // {
              //     name: translate("description"),
              //     key: "description",
              // },
              {
                name: "TxHash",
                key: "description",
                render: (item) => {
                  const hash = ObjectUtils.getIn(item, "transactionHash");
                  if (hash)
                    if (item.coinId === Gp2Id)
                      return (
                        <a href={`${getEnv("BSC_SCAN")}${hash}`} target="__blank">
                          Click me
                        </a>
                      );
                    else
                      return (
                        <a href={`${getEnv("TRON_SCAN")}${hash}`} target="__blank">
                          Click me
                        </a>
                      );

                  return "--";
                },
              },
            ]}
            fetchData={async (state) => {
              let params = { ...state };
              if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
              if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

              params["transactionTypeId"] = 63; //airdrop
              return AdminService.getListTransactions({
                page: params.pageNumber,
                numberOfTransactionsPerPage: params.limit,
                ...params,
              });
            }}
          />
        </div>
      );
    },
    () => true
  )
);
