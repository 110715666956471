import React from "react";
import { withConfigWraper } from "../wraper";
import NewsCompnt from "./news-compnt";

type Props = {};

const NewsPage = withConfigWraper((props: Props) => {
  return (
    <div className="row mb20">
      <div className="col-sm-12">
        <NewsCompnt />
      </div>
    </div>
  );
});

export default NewsPage;
