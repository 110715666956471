import React from "react";
import { InputNumber } from "../../../components";
import { Button, CreateAlert, InputWraper, onError, useForm } from "../../../modules";
import * as Yup from "yup";
import { translate } from "../../../languages";
import { useSelector } from "../../../store";
import { ConfigService } from "../../../services/config/config.service";
import { store } from "../../../store";
import { getEnv } from "../../../configs";

const RankConfigWithdraw = () => {
  const rankConfigWithdraw = useSelector((state) => state.rankConfigWithdraw);

  const structure = rankConfigWithdraw
    ?.map((item: any) => {
      return [
        {
          name: `rank${item?.rank}Day`,
          defaultValue: Number(item?.withdrawLimitDay),
          validate: Yup.number()
            .required(translate("must be provided"))
            .min(1, translate("not filled in zero")),
        },
        {
          name: `rank${item?.rank}Week`,
          defaultValue: Number(item?.withdrawLimitWeek),
          validate: Yup.number()
            .required(translate("must be provided"))
            .min(1, translate("not filled in zero")),
        },
      ];
    })
    .flat();

  const { handleSubmit, getInputProps } = useForm({
    structure,
    onSubmit: async (values) => {
      const data = rankConfigWithdraw?.map((item: any, idx: number) => {
        return {
          rank: item?.rank,
          withdrawLimitDay: values[`rank${idx}Day`],
          withdrawLimitWeek: values[`rank${idx}Week`],
        };
      });

      return ConfigService.updateListRankConfigWithdraw({
        data: data,
      })
        .then(async () => {
          CreateAlert({ type: "success", message: "Set rank config withdraw successful." });
          await ConfigService.getListRankConfigWithdraw(store);
        })
        .catch(onError);
    },
  });

  const renderInputValue = () => {
    return rankConfigWithdraw?.map((item: any, idx: number) => {
      return (
        <div
          key={item?.rankConfigId}
          className={`row justify-content-between ${
            idx === 0 ? "align-items-center" : "align-items-baseline"
          } `}
        >
          <h3 className={`col-sm ${idx === 0 && "mt8"}`}>VIP {item?.rank}</h3>
          <InputWraper
            className="col-sm-5"
            label={`${idx === 0 ? "Withdraw Max On Day" : ""}`}
            inputProps={getInputProps(`rank${item?.rank}Day`)}
            renderInput={(props) => (
              <InputNumber {...props} decima={+getEnv("NUMBER_DECIMAL_DISPLAY")} />
            )}
          />
          <InputWraper
            className="col-sm-5"
            label={`${idx === 0 ? "Withdraw Max On Week" : ""}`}
            inputProps={getInputProps(`rank${item?.rank}Week`)}
            renderInput={(props) => (
              <InputNumber {...props} decima={+getEnv("NUMBER_DECIMAL_DISPLAY")} />
            )}
          />
        </div>
      );
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      {renderInputValue()}
      <Button label="Update" isMiddle buttonType="success" className="btnBan" type="submit" />
    </form>
  );
};

export default RankConfigWithdraw;
