import React, { FC } from "react";
import * as Yup from "yup";
import { InputNumber, InputPassword, InputText } from "../../../components";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../modules";
import { UserService } from "../../../services";
import { ConfigService } from "../../../services/config/config.service";
import { withConfigWraper } from "../wraper";

const UserWithdrawLimit: FC<any> = withConfigWraper(() => {
  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "email",
        validate: Yup.string().required("Must be provided"),
      },
      {
        name: "limitDay",
        validate: Yup.string().required("Must be provided"),
        defaultValue: 0,
      },
    ],
    onSubmit: async (values) => {
      let payload = {
        email: values?.email,
        limitDay: values?.limitDay,
      };
      ConfigService.userWithdrawLimit(payload)
        .then((res) => {
          CreateAlert({ type: "success", message: "Successful configuration" });
        })
        .catch((err) => {
          CreateAlert({ type: "danger", message: translate(err.message) });
        })
        .finally(() => {});
    },
  });

  return (
    <div className="row">
      <div className="col-sm-6">
        <div className="row">
          <div className="col-8 ">
            <InputWraper label={"Email User"} inputProps={getInputProps("email")} component={InputText} />
            <InputWraper label={"Amount"} inputProps={getInputProps("limitDay")} component={InputNumber} />
          </div>
        </div>
        <Button label={"Submit"} type="submit" buttonType="success" isLoading={isSubmitting} onClick={handleSubmit} />
      </div>
    </div>
  );
});

export default UserWithdrawLimit;
