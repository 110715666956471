import { RequestMainService } from "../request";
import { CookieService, ECookieVariable } from "../cookie";

export class OrderService {
    static async getOpenedOrders() {
        const status: string[] = ['OPEN'];

        let statusString = '';

        status.map((item) => {
            statusString += `&status=${item}`
            return item
        })

        const count = await RequestMainService.get(`/admin/order?${statusString}`, {
            symbolId: CookieService.get(ECookieVariable.USER_SYMBOL_ACTIVE_ID),
            page: 1,
            pageSize: 1,
        }).then(({ result }) => result.total)

        if (!count) return []

        return RequestMainService.get(`/admin/order?${statusString}`, {
            symbolId: CookieService.get(ECookieVariable.USER_SYMBOL_ACTIVE_ID),
            page: 1,
            pageSize: count,
        })
            .then(({ result }) => result.orders)
    }
}