import { createStore } from 'redux'
import { TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import { reducers } from './reducers';
import { isDev } from '../configs';

export const store = createStore(reducers, isDev ? composeWithDevTools() : undefined)

export type RootState = ReturnType<typeof reducers>
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector
