import React, { FC, memo } from 'react'

import { ResultsPanel } from './results-panel'
import { formatDroplets, useTradePannel } from './TradePannel.logic';

export const dropletsPerCol = 6;
export const totalCol = 14;
export const totalDroplets = dropletsPerCol * totalCol;
export const totalBlankDroplets = 60;
export const minTotalInitialDot = totalDroplets - totalBlankDroplets;

export const TradePannel: FC = memo(() => {
    const { last100Reports, droplets } = useTradePannel();

    return (
        <div className="TradePannel">
            {/* <ChartTrade data={chartData} /> */}
            <ResultsPanel last100Reports={last100Reports} droplets={formatDroplets(droplets)} />
        </div>
    )
}, () => true)