const defaultState = null

export const SET_USER_KYC = 'SET_USER_KYC';

export const userKYCReducer = (state = defaultState, action: any) => {
    const { type, data, error } = action;
    if (type === SET_USER_KYC) {
        if (error) return { error }
        if (data) return data;
    }
    return state;
}