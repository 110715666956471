import React, { memo } from "react";
import * as Yup from "yup";
import { InputText } from "../../../components";
import { Button, CreateAlert, InputWraper, onError, useForm } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withTransactionWraper } from "../wraper";

export const DepositSupport = withTransactionWraper(
  memo(() => {
    const { handleSubmit, getInputProps } = useForm({
      structure: [
        {
          name: "blockLists",
          // defaultValue: password,
          description: "* Format block list: number block 1, number block 2, number block 3,...",
          validate: Yup.string().required("Block Lists is required"),
        },
      ],
      onSubmit: async (values) => {
        AdminService.supportDeposit(values.blockLists)
          .then((res) => {
            if (res.success) {
              CreateAlert({
                message: "Submit block lists success.",
                type: "success",
              });
              clearForm();
            } else {
              CreateAlert({
                message: "Error when submit block lists.",
                type: "danger",
              });
            }
          })
          .catch(onError);
      },
    });

    const blockLists = getInputProps("blockLists");

    const clearForm = () => {
      blockLists.onChange("");
      // console.log("sss");
    };

    return (
      <div className="row mb20">
        <div className="col-sm-5">
          <form className="blockLists" onSubmit={handleSubmit}>
            <InputWraper
              label={"Block Lists"}
              inputProps={getInputProps("blockLists")}
              component={InputText}
            />
            <div>
              <Button
                // className="mb20"
                label="Submit"
                // onClick={props.onClick}
                isMiddle
                // buttonType="warning"
                className="btnBan"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    );
  })
);
