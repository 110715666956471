import React, { FC, useEffect, useState } from "react";
import { PopupWraper } from "../popup";
import { Table } from "../../modules";
import { AdminService } from "../../services/admin";

// function selects(obj: any, keys: any[]) {
//     return keys.reduce((acc, key) => {
//         acc[key] = obj[key];
//         return acc;
//     }, {})
// }

export function flatTreeData(input: any[]) {
  try {
    let output = [];

    const getChildren = (obj: any) => {
      if (obj && obj.children && obj.children.length > 0) {
        const { children } = obj;

        children.map((item: any) => {
          output.push({ ...item });
          getChildren(item);
          return item;
        });
      }
    };

    output.push(input);
    getChildren(input);

    output.map((item: any) => {
      delete item.children;
      return item;
    });

    return output;
  } catch (error) {
    return [];
  }
}

export const TreeView: FC<{ affiliate: any[]; onGetTree: (id: any) => void; openPopupMoveBranh: (nodeData: any) => void }> = (props) => {
  let [affiliation, setAffiliation] = useState(null as any);
  const [sponsorUserId, setSponsorUserId] = useState<any>(null);

  useEffect(() => {
    setTree();
    // eslint-disable-next-line
  }, []);

  const setTree = () => {
    // @ts-ignore
    const OrgChart: any = window ? window.OrgChart : undefined;

    if (OrgChart) {
      const { affiliate, onGetTree } = props;
      setAffiliation(affiliate);
      // console.log("affiliate",affiliate);

      const data = affiliate.map((item: any) => {
        let email = item.email;
        let level = item.rank;
        let img = `/assets/images/rank/rank_${item.rank}.png`;

        return {
          // ...selects(item, ['email', 'parentId', 'level', 'volume', 'numberOfF1Agency', 'volumeAllBranch']),
          id: item.userId,
          pid: item.presenterId,
          "Username login": item.userNameLogin,
          Email: email,
          Level: level,
          "Personal volume": item.volume,
          "Downlines volume": item.volumeAllBranch,
          img,
        };
      });

      //[BEGIN] - customize template
      OrgChart.templates.myTemplate = Object.assign({}, OrgChart.templates.polina);
      OrgChart.templates.myTemplate.node =
        '<rect x="0" y="0" height="60" width="300" fill="#039BE5" stroke-width="1" stroke="#686868" rx="30" ry="30"></rect>';
      OrgChart.templates.myTemplate.size = [300, 60];
      OrgChart.templates.myTemplate.img_0 =
        '<clipPath id="ulaImg">' +
        '<circle cx="20" cy="20" r="35"></circle>' +
        "</clipPath>" +
        '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#ulaImg)" xlink:href="{val}" x="8" y="5" width="35" height="45">' +
        "</image>";
      OrgChart.templates.myTemplate.field_0 =
        '<text width="210" class="field_0" style="font-size: 18px;" fill="#ffffff" x="48" y="26" text-anchor="start">{val}</text>';
      OrgChart.templates.myTemplate.field_1 =
        '<text width="210" class="field_1" style="font-size: 14px;" fill="#ffffff" x="48" y="45" text-anchor="start">{val}</text>';
      OrgChart.templates.myTemplate.nodeMenuButton =
        '<g style="cursor:pointer;" transform="matrix(1,0,0,1,270,23)" control-node-menu-id="{id}"><rect x="-4" y="-10" fill="#000000" fill-opacity="0" width="22" height="22"></rect><circle cx="0" cy="0" r="2" fill="#ffffff"></circle><circle cx="0" cy="7" r="2" fill="#ffffff"></circle><circle cx="0" cy="14" r="2" fill="#ffffff"></circle></g>';
      //[END] - customize template

      var treeIcon =
        '<svg width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M17,16l-4-4V8.82a3,3,0,1,0-2,0V12L7,16H3v5H8V17.95l4-4.2,4,4.2V21h5V16Z" transform="translate(-3 -3)" /></svg>';
      var iconMove = `<svg width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 19"><path d="M21.99,8a1.982,1.982,0,0,0-.94-1.7L12,1,2.95,6.3A1.991,1.991,0,0,0,2,8V18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2ZM12,13,3.74,7.84,12,3l8.26,4.84Z" transform="translate(-2 -1)" /></svg>`;
      var chart = new OrgChart(document.getElementById("tree"), {
        template: "myTemplate",
        enableSearch: false,
        nodeBinding: {
          field_0: "Username login",
          field_1: "Email",
          field_2: "Level",
          field_3: "Personal volume",
          field_4: "Downlines volume",
          img_0: "img",
        },
        orientation: OrgChart?.orientation?.left,
        collapse: { level: 1, allChildren: true },
        mouseScrool: OrgChart?.action?.ctrlZoom,
        enableKeyNavigation: true,
        levelSeparation: 200, //The gap between each level. Default value - 60
        siblingSeparation: 20, //The gap between nodes in a subtree. Default value - 20
        subtreeSeparation: 40, //The gap between subtrees. Default value - 40
        sticky: true, //Stops the chart locking to the top of the screen once you move it.
        lazyLoading: false,
        nodeMenu: {
          call: {
            text: "Get tree",
            icon: treeIcon,
            onClick: (nodeId: any) => getTreeHandler(nodeId),
          },
          call2: {
            text: "Get referral sponsor",
            icon: treeIcon,
            // onClick: (nodeId: any) => handleClickGetReferralSponsor(nodeId),
            onClick: (nodeId: any) => setSponsorUserId(nodeId),
          },
          move: {
            text: "Move branch",
            icon: iconMove,
            onClick: (nodeId: any) => props.openPopupMoveBranh(chart.get(nodeId)),
          },
        },
        nodes: data,
      });

      chart.on("expcollclick", (sender: any, collapse: any, id: any, ids: any) => {
        collapse === 0 &&
          AdminService.getAffiliation(2, id)
            .then(async (res) => {
              const newRes = await res
                ?.filter((item: any) => +item?.level === +res?.[0]?.level + 2)
                ?.filter((item: any) => !data?.map((el) => el?.id)?.includes(item?.userId))
                ?.map((item: any) => {
                  let email = item.email;
                  let level = item.rank;
                  let img = `/assets/images/rank/rank_${item.rank}.png`;

                  return {
                    // ...selects(item, ['email', 'parentId', 'level', 'volume', 'numberOfF1Agency', 'volumeAllBranch']),
                    id: item.userId,
                    pid: item.presenterId,
                    "Username login": item.userNameLogin,
                    Email: email,
                    Level: level,
                    "Personal volume": item.volume,
                    "Downlines volume": item.volumeAllBranch,
                    img,
                  };
                });

              newRes?.forEach((item: any) => {
                chart.addNode(item);
              });

              sender.center(id, {
                childrenState: OrgChart.COLLAPSE_SUB_CHILDRENS,
              });
            })

            .catch((err) => setAffiliation({ error: err.error }));
      });

      const getTreeHandler = (nodeId: any) => {
        // console.log("nodeId",nodeId);

        var nodeData = chart.get(nodeId);
        // console.log("nodeData",nodeData);
        onGetTree(nodeData.id);

        // var employeeName = nodeData["name"];
      };
    }

    // eslint-disable-next-line
  };

  if (affiliation) {
    if (affiliation[0].userId !== props.affiliate[0].userId) {
      setTree();
      setAffiliation(props.affiliate);
    }
  }

  return (
    <div className="TreeView">
      <div id="tree" />
      {sponsorUserId != null && (
        <PopupWraper center title="Referral sponsor" onClose={() => setSponsorUserId(null)}>
          <Table
            structure={[
              {
                name: "Email",
                key: "email",
              },
              {
                name: "Username login",
                key: "userNameLogin",
              },
              {
                name: "Tier",
                key: "level",
              },
            ]}
            fetchData={async (params) => {
              return AdminService.getReferralSponsor(sponsorUserId, 0, {
                page: params.pageNumber,
                pageSize: params.limit,
                ...params,
              });
            }}
          />
        </PopupWraper>
      )}
    </div>
  );
};
