import React, { FC } from "react";
import * as Yup from "yup";
import { InputText } from "../../../components";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../modules";
import { AdminService } from "../../../services/admin";
import "./index.scss";

export const CompleteWithdraw: FC<{ id: any; onFinishEdit: () => void }> = (props) => {
  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: "transactionHash",
        validate: Yup.string().required(translate("must-be-provided")),
      },
    ],
    onSubmit: async (values) => {
      return AdminService.completeWithdraw(props.id, values.transactionHash)
        .then(() => {
          CreateAlert({
            message: "Complete success.",
            type: "success",
          });
          props.onFinishEdit();
        })
        .catch((error) => {
          CreateAlert({
            message: translate(error.message),
            type: "danger",
          });
        });
    },
  });
  return (
    <form className="CompleteWithdraw" onSubmit={handleSubmit}>
      <InputWraper label={"Tranaction Hash"} inputProps={getInputProps("transactionHash")} component={InputText} />

      <div>
        <Button
          // className="mb20"
          label="Submit"
          // onClick={props.onClick}
          isMiddle
          buttonType="success"
          className="btnBan"
          type="submit"
        />
      </div>
    </form>
  );
};
