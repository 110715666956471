import React, { FC, memo, useEffect, useState } from 'react'
import { setIntervalAsync, clearIntervalAsync } from 'set-interval-async/fixed';

import { useTradeCoin } from '../../hook';
import { EOrderOption } from '../../types';
import { getLocaleKey } from '../../languages';
import { ITableStructureItem, ObjectUtils } from '../../modules';
import { OrderService } from '../../services/order/order.service';
import { SetOpenedOrders } from '../sentiments';

export const OpennedOrders: FC = memo(() => {
    const { coinActive, getIsDemo } = useTradeCoin();
    const [openedOrders, setOpenedOrders] = useState([] as any[]);

    const soundWin = new Audio('/assets/sound/new-order.wav');
    const playSoundWin = () => {
        const isEnableSound = localStorage.getItem('sound') !== 'off';
        if (!isEnableSound) return;
        soundWin.muted = false;
        soundWin.play();
    };

    let higherOrders: any[] = openedOrders.filter((item: any) => item.coinId === coinActive.coinId && item.option === EOrderOption.HIGHER).sort((a, b) => b.amount - a.amount);
    let lowerOrders: any[] = openedOrders.filter((item: any) => item.coinId === coinActive.coinId && item.option === EOrderOption.LOWER).sort((a, b) => b.amount - a.amount);

    useEffect(() => {
        let interval: any;

        OrderService.getOpenedOrders()
            .then((data) => {
                setOpenedOrders(data);

                interval = setIntervalAsync(async () => {
                    return OrderService.getOpenedOrders()
                        .then((newData) => {
                            SetOpenedOrders(newData);
                            setOpenedOrders(state => {
                                const isDemo = getIsDemo();
                                if (newData.length > 0
                                    && newData.length > state.length
                                    && !isDemo) playSoundWin();
                                return newData;
                            })
                        })
                }, 1000)
            })

        return () => {
            try {
                clearIntervalAsync(interval);
            } catch (error) { }
        }
        // eslint-disable-next-line
    }, [])

    const structure: ITableStructureItem[] = [
        {
            name: 'S',
            render: (item) => new Date(item.created).getSeconds()
        },
        {
            name: 'Email',
            key: 'email',
        },
        {
            name: 'Amount',
            render: (item) => (+item.amount).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })
        },
        {
            name: 'Balance',
            render: (item) => (+item.usdpBalance).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })
        },
        {
            name: '%',
            render: (item) => `${((+item.amount / +item.usdpBalance) * 100).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })}%`
        },
    ]

    return (
        <div className="OpennedOrders">
            <div className="title">
                List opening orders
            </div>
            <div className="tabs">
                <div className="item down">
                    <div className="label">Down</div>
                    <div className="content">
                        <div className="Table">
                            <table>
                                <thead className="Table__Head">
                                    <tr>
                                        {structure.map((item, key) => (
                                            <th key={key}>
                                                {item.name}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody className="Table__Body">
                                    {lowerOrders.map((item, itemKey) => {
                                        return (
                                            <tr key={itemKey}>
                                                {structure.map((column, columnKey) => {
                                                    return (
                                                        <td className={column.className || ''} style={column.style} key={columnKey}>
                                                            {(() => {
                                                                if (column.render) return column.render(item, () => false, column);
                                                                if (column.key) return `${ObjectUtils.getIn(item, column.key, '')}`;
                                                                return '--';
                                                            })()}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="item up">
                    <div className="label">Up</div>

                    <div className="content">
                        <div className="Table">
                            <table>
                                <thead className="Table__Head">
                                    <tr>
                                        {structure.map((item, key) => (
                                            <th key={key}>
                                                {item.name}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody className="Table__Body">
                                    {higherOrders.map((item, itemKey) => {
                                        return (
                                            <tr key={itemKey}>
                                                {structure.map((column, columnKey) => {
                                                    return (
                                                        <td className={column.className || ''} style={column.style} key={columnKey}>
                                                            {(() => {
                                                                if (column.render) return column.render(item, () => false, column);
                                                                if (column.key) return `${ObjectUtils.getIn(item, column.key, '')}`;
                                                                return '--';
                                                            })()}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})