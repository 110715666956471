import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Icon } from "../../modules";

export const CheckboxCompnt: FC<any> = (props: any) => {
  const [checked, setChecked] = useState<boolean>(_.isNil(props?.checked) ? false : props?.checked);
  useEffect(() => {
    setChecked(_.isNil(props?.checked) ? false : props?.checked);
    // eslint-disable-next-line
  }, [props?.checked]);

  return (
    <div className="checkbox-compnt">
      {checked === true && (
        <span
          className="checkbox-compnt__icon"
          onClick={() => {
            setChecked(false);
            props?.onChange(_.isNil(props?.value) ? "" : props?.value, false);
          }}
        >
          <Icon.CheckboxChecked />
        </span>
      )}
      {checked === false && (
        <span
          className="checkbox-compnt__icon"
          onClick={() => {
            setChecked(true);
            props?.onChange(_.isNil(props?.value) ? "" : props?.value, true);
          }}
        >
          <Icon.CheckboxUnchecked />
        </span>
      )}
    </div>
  );
};
