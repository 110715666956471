import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { InputText } from "../../../../components";
import { InputImageSingle } from "../../../../components/single-image";
import { Button, CreateAlert, InputWraper, useForm } from "../../../../modules";
import { AdminService } from "../../../../services/admin";
import { GameManagementLayout } from "../../components";
import "./index.scss";

export const SupplierCreatePage: FC<any> = GameManagementLayout((props: any) => {
  const history = useHistory();
  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: "code",
        validate: Yup.string().required("Must be provided"),
      },
      {
        name: "name",
        validate: Yup.string().required("Must be provided"),
      },
      {
        name: "image",
        validate: Yup.string().required("Must be provided"),
      },
      {
        name: "thumnail",
        validate: Yup.string().required("Must be provided"),
      },
    ],
    onSubmit: async (values) => {
      const { thumnail, image } = values;

      AdminService.addSupplier({
        ...values,
        isActive: true,
        thumnail: thumnail ? thumnail?.src : undefined,
        image: image ? image?.src : undefined,
      })
        .then(() => {
          CreateAlert({
            message: "Add success.",
            type: "success",
          });
        })
        .catch((error: any) => {
          CreateAlert({
            message: error.message,
            type: "danger",
          });
        })
        .finally(() => {
          history.push("/game-management-supplier");
        });
    },
  });
  return (
    <div className="supplier-create-page container-fluid">
      <div className="row">
        <div className="col-6">
          <div className="supplier-create-page__content">
            <div className="title">Create Supplier</div>
            <div className="form container">
              <div className="row">
                <div className="col-6 input-container">
                  <InputWraper
                    label={"Code"}
                    inputProps={getInputProps("code")}
                    component={InputText}
                  />
                </div>
                <div className="col-6 input-container">
                  <InputWraper
                    label={"Name"}
                    inputProps={getInputProps("name")}
                    component={InputText}
                    className="mr5"
                  />
                </div>
                <div className="col-6 input-container">
                  <InputWraper
                    className={"image"}
                    label={"Image"}
                    inputProps={getInputProps("image")}
                    component={InputImageSingle}
                  />
                </div>
                <div className="col-6 input-container">
                  <InputWraper
                    className={"image-thumbnail"}
                    label={"Thumbnail"}
                    inputProps={getInputProps("thumnail")}
                    component={InputImageSingle}
                  />
                </div>
                <div style={{ textAlign: "center" }} className="col-12">
                  <Button
                    label="Create"
                    buttonType="success"
                    onClick={handleSubmit}
                    // isLoading={isSubmitting}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="supplier-create-page__cover">
            <img
              className="cover-image"
              src="/assets/images/create-supplier-cover-image.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
});
