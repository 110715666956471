import capitalize from "lodash/capitalize";
import React, { memo, useState } from "react";
import {
  TableFilterInputText,
  TableFilterRangeTimeInput,
} from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { getLocaleKey, translate } from "../../../languages";
import { Button, CreateAlert, ObjectUtils, onError, Table } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withTransactionWraper } from "../wraper";

export const TransactionAdminWithdrawList = withTransactionWraper(
  memo(
    () => {
      // const last1MothDate = Date.now() - 1000 * 60 * 60 * 24 * 30;
      const last1WeekDate = Date.now() - 1000 * 60 * 60 * 24 * 7;
      const [disabledButton, setDisabledButton] = useState(true);
      const [totalETH, setTotalETH] = useState(0);
      const [totalUSDT, setTotalUSDT] = useState(0);
      const [totalBNB, setTotalBNB] = useState(0);
      const [status, setStatus] = useState("");
      const [errorMassage, setErrorMassage] = useState("");
      const [labelButton, setLabelButton] = useState("Start withdraw to admin wallet");

      enum ERunWithdrawForAdminStatus {
        START = "START",
        DOING_TRANFER_FEE = "DOING_TRANFER_FEE",
        DONE_TRANFER_FEE = "DONE_TRANFER_FEE",
        DOING_WITHDRAW = "DOING_WITHDRAW",
        DONE_WITHDRAW = "DONE_WITHDRAW",
        ERROR = "ERROR",
      }

      enum ERunWithdrawForAdminLabel {
        START = "Start withdraw to admin wallet",
        REPAIRE = "Repaire withdraw to admin wallet",
        DOING_TRANFER_FEE = "Doing transfer fee",
        DONE_TRANFER_FEE = "Done transfer fee",
        DOING_WITHDRAW = "Doing withdraw",
      }

      const coinLists = [
        {
          label: "Ethereum",
          value: 1,
        },
        {
          label: "USDT",
          value: 3,
        },
        {
          label: "BNB",
          value: 0,
        },
      ];
      const transactionLists = [
        {
          label: "Withdraw",
          value: 2,
        },
        {
          label: "Withdraw fee",
          value: 6,
        },
      ];

      const UsdId = 2;
      const ButId = 5;
      const DemoId = 5;

      const onClick = () => {
        AdminService.startWithdrawToAdminWallet()
          .then((res) => {
            if (res.success) {
              CreateAlert({
                message: "Start withdraw to admin wallet successfully.",
                type: "success",
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              CreateAlert({
                message: "Error when start withdraw to admin wallet.",
                type: "danger",
              });
            }
          })
          .catch(onError);
      };

      const onSetLabelButton = (status: any) => {
        if (status === ERunWithdrawForAdminStatus.DOING_TRANFER_FEE) {
          setLabelButton(ERunWithdrawForAdminLabel.DOING_TRANFER_FEE);
        } else if (status === ERunWithdrawForAdminStatus.DOING_WITHDRAW) {
          setLabelButton(ERunWithdrawForAdminLabel.DOING_WITHDRAW);
        } else if (status === ERunWithdrawForAdminStatus.START) {
          setLabelButton(ERunWithdrawForAdminLabel.REPAIRE);
        } else if (status === ERunWithdrawForAdminStatus.DONE_TRANFER_FEE) {
          setLabelButton(ERunWithdrawForAdminLabel.DONE_TRANFER_FEE);
        } else {
          setLabelButton(ERunWithdrawForAdminLabel.START);
        }
      };

      return (
        <div>
          <div className="row mb20">
            <div className="col-sm-4">
              <form className="withdrawToAdminWalletForm">
                <div>
                  <Button
                    // className="mb20"
                    label={labelButton}
                    onClick={() => onClick()}
                    // isMiddle
                    // buttonType="warning"
                    className="btnBan"
                    type="button"
                    disabled={disabledButton}
                  />
                </div>
              </form>
              {status === ERunWithdrawForAdminStatus.ERROR ? (
                <span className="textDanger">Error: {errorMassage}</span>
              ) : null}
              {/* <span className="textDanger">Error: {errorMassage}</span> */}
            </div>
            <div className="col-sm-8 text-right">
              <label className="">
                Ethereum total:{" "}
                <span className="textSuccess">
                  {totalETH.toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 8,
                    minimumFractionDigits: 8,
                  })}
                </span>
              </label>
              <label className="">
                {" "}
                - USDT total:{" "}
                <span className="textSuccess">
                  {totalUSDT.toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 8,
                    minimumFractionDigits: 8,
                  })}
                </span>
              </label>
              <label>
                {" "}
                - BNB total (fee):{" "}
                <span className="textSuccess">
                  {totalBNB.toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 8,
                    minimumFractionDigits: 8,
                  })}
                </span>
              </label>
            </div>
          </div>
          <div className={"TransactionAdminWithdrawList"}>
            <Table
              itemPerPages={10}
              filters={[
                {
                  name: "Coin",
                  key: "coinId",
                  input: (e) => (
                    <TableFilterInputSelect
                      {...e}
                      options={coinLists.filter((item: any) => item.value !== 6)}
                    />
                  ),
                  // defaultValue: 2
                },
                {
                  name: translate("time"),
                  key: "created",
                  input: (e) => (
                    <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />
                  ),
                  defaultValue: {
                    fromDate: new Date(last1WeekDate),
                    toDate: new Date(),
                  },
                },
                {
                  name: "Email",
                  key: "email",
                  input: TableFilterInputText,
                },
                {
                  name: translate("type"),
                  key: "transactionTypeId",
                  input: (s) => <TableFilterInputSelect {...s} options={transactionLists} />,
                },
              ]}
              structure={[
                {
                  name: "Coin",
                  key: "coinId",
                  render: (item) => {
                    const coin = coinLists.find((v: any) => v.value === item.coinId);
                    return ObjectUtils.getIn(coin, "label", "--");
                  },
                },
                {
                  name: translate("time"),
                  key: "created",
                  render: (item) => {
                    const date = new Date(item.created);
                    return (
                      <span>
                        {date.toLocaleTimeString(getLocaleKey())} <br />
                        {date.toLocaleDateString(getLocaleKey())}
                      </span>
                    );
                  },
                },
                {
                  name: "Email",
                  key: "email",
                },
                {
                  name: translate("amount"),
                  key: "value",
                  render: (item) => {
                    // const { balanceAfter, balanceBefore } = item;
                    // let isIncrease = false;
                    // if (balanceAfter > balanceBefore) isIncrease = true;
                    return (
                      <span className="textSuccess">
                        {(+item.value).toLocaleString(getLocaleKey(), {
                          maximumFractionDigits:
                            item.coinId === UsdId || item.coinId === ButId || item.coinId === DemoId
                              ? 2
                              : 8,
                          minimumFractionDigits:
                            item.coinId === UsdId || item.coinId === ButId || item.coinId === DemoId
                              ? 2
                              : 8,
                        })}
                      </span>
                    );
                  },
                },
                {
                  name: translate("type"),
                  key: "transactionTypeId",
                  render: (item) =>
                    ObjectUtils.getIn(
                      capitalize(item.transactionType.name.replace(/_/g, " ")),
                      "",
                      "--"
                    ),
                  //render: (item) => ObjectUtils.getIn(item, 'transactionType.name', '--'),
                },
                {
                  name: "Txhash",
                  key: "description",
                  render: (item) => {
                    const hash = ObjectUtils.getIn(item, "transactionHash");
                    if (hash)
                      return (
                        <a href={`https://bscscan.com/tx/${hash}`} target="__blank">
                          Click me
                        </a>
                      );

                    return "--";
                  },
                },
              ]}
              fetchData={async (state) => {
                let params = { ...state };
                console.log("params", params);

                if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
                if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

                return AdminService.getListTransactionsAdminWithdraw({
                  page: params.pageNumber,
                  numberOfTransactionsAdminWithdrawPerPage: params.limit,
                  ...params,
                }).then((res) => {
                  // console.log("result", res);
                  if (res) {
                    setStatus(res.result.statusRunWithdraw);
                    if (res.result.statusRunWithdraw) {
                      onSetLabelButton(res.result.statusRunWithdraw);
                    }
                    const sum = res.result.sum;
                    const totalETH = sum.find((item: any) => item.coinId === coinLists[0].value);
                    if (totalETH) {
                      setTotalETH(totalETH.sum);
                    } else {
                      setTotalETH(0);
                    }
                    const totalUSDT = sum.find((item: any) => item.coinId === coinLists[1].value);
                    if (totalUSDT) {
                      setTotalUSDT(totalUSDT.sum);
                    } else {
                      setTotalUSDT(0);
                    }
                    const totalBNB = sum.find((item: any) => item.coinId === coinLists[2].value);
                    if (totalBNB) {
                      setTotalBNB(totalBNB.sum);
                    } else {
                      setTotalBNB(0);
                    }
                    if (res.result.statusRunWithdraw === ERunWithdrawForAdminStatus.ERROR) {
                      setErrorMassage(res.result.errorMessage);
                    }
                  }
                  if (
                    !res.result.statusRunWithdraw ||
                    res.result.statusRunWithdraw === ERunWithdrawForAdminStatus.ERROR ||
                    res.result.statusRunWithdraw === ERunWithdrawForAdminStatus.DONE_WITHDRAW
                  ) {
                    setDisabledButton(false);
                  }

                  return {
                    count: res.result.total,
                    data: res.result.transactions,
                  };
                });
              }}
            />
          </div>
        </div>
      );
    },
    () => true
  )
);
