import { useEffect } from "react";

import { useSelector } from "../store";
import { ObjectUtils } from "../modules";
import { CookieService, ECookieVariable, MainService, SocketService, INCREASE_APP_LIFE_CYCLE } from "../services";
import { getEnv } from "../configs";
import { TUseTradeCoinExports, TTradeCoinItem } from "./types";
import { translate } from "../languages";
import { useStore } from "react-redux";

export const useAppLifeCycle = (handleChange?: () => void) => {
    const lifeCycleTime = useSelector(state => state.main.lifeCycleTime);
    const store = useStore();

    useEffect(() => {
        if (lifeCycleTime > 1 && handleChange) handleChange();
        // eslint-disable-next-line
    }, [lifeCycleTime])

    const updateLifeCycle = () => store.dispatch({ type: INCREASE_APP_LIFE_CYCLE });

    return { lifeCycleTime, updateLifeCycle }
}

export const useTradeCoin = () => {
    useAppLifeCycle();

    const coinsState = useSelector(state => state.coins);
    const coins: any[] = ObjectUtils.getIn(coinsState, 'data', []);

    const tradeRealCoinCode = getEnv('TRADE_REAL_COIN_CODE');
    const tradeDemoCoinCode = getEnv('TRADE_DEMO_COIN_CODE');

    let tradeCoinCode: string = CookieService.get(ECookieVariable.USER_TRADE_COIN);

    // Validate
    if (![tradeRealCoinCode, tradeDemoCoinCode].includes(tradeCoinCode)) {
        tradeCoinCode = tradeRealCoinCode;
        CookieService.set(ECookieVariable.USER_TRADE_COIN, tradeRealCoinCode)
    }

    const realCoin = coins.find(v => v.code === tradeRealCoinCode) || {};
    const demoCoin = coins.find(v => v.code === tradeDemoCoinCode) || {};

    const data = [
        {
            type: 'REAL',
            label: translate('live-account'),
            isActive: tradeCoinCode === tradeRealCoinCode,
            coinId: realCoin.coinId,
        },
        {
            type: 'DEMO',
            label: translate('demo-account'),
            isActive: tradeCoinCode === tradeDemoCoinCode,
            coinId: demoCoin.coinId,
        },
    ]

    const coinActive = data.find(v => v.isActive) || data[0]

    const getIsDemo = (): boolean => CookieService.get(ECookieVariable.USER_TRADE_COIN) === 'DEMO'

    return {
        coinActive,
        isDemo: coinActive.type === 'DEMO',
        getIsDemo,
    }
}

export const useTradeCoinWithBalance = (): TUseTradeCoinExports => {
    useAppLifeCycle();

    const userWalletBalancesState = useSelector(state => state.userWalletBalances);
    const balances: any[] = ObjectUtils.getIn(userWalletBalancesState, 'data', []);

    const coinsState = useSelector(state => state.coins);
    const coins: any[] = ObjectUtils.getIn(coinsState, 'data', []);

    const tradeRealCoinCode = getEnv('TRADE_REAL_COIN_CODE');
    const tradeDemoCoinCode = getEnv('TRADE_DEMO_COIN_CODE');

    let tradeCoinCode: string = CookieService.get(ECookieVariable.USER_TRADE_COIN);

    // Validate
    if (![tradeRealCoinCode, tradeDemoCoinCode].includes(tradeCoinCode)) {
        tradeCoinCode = tradeRealCoinCode;
        CookieService.set(ECookieVariable.USER_TRADE_COIN, tradeRealCoinCode)
    }

    const realBalance = balances.find((coin) => coin.code === tradeRealCoinCode);
    const realCoin = coins.find(v => v.code === tradeRealCoinCode) || {};

    const demoBalance = balances.find((coin) => coin.code === tradeDemoCoinCode);
    const demoCoin = coins.find(v => v.code === tradeDemoCoinCode) || {};

    const handleChangeTradeCoin = () => {
        CookieService.set(
            ECookieVariable.USER_TRADE_COIN,
            tradeCoinCode === 'DEMO'
                ? tradeRealCoinCode
                : tradeDemoCoinCode
        );
        MainService.forceUpdateApp();
        SocketService.initialize(true);
    }


    const data: TTradeCoinItem[] = [
        {
            type: 'REAL',
            label: translate('live-account'),
            amount: realBalance ? realBalance.amount : 0,
            isActive: tradeCoinCode === tradeRealCoinCode,
            coinId: realCoin.coinId,
        },
        {
            type: 'DEMO',
            label: translate('demo-account'),
            amount: demoBalance ? demoBalance.amount : 0,
            isActive: tradeCoinCode === tradeDemoCoinCode,
            coinId: demoCoin.coinId,
        },
    ]

    const coinActive = data.find(v => v.isActive) || data[0]
    const coinsArr = coinActive.type === 'DEMO' ? coins.filter((v: any) => v.code === 'DEMO') : coins.filter((v: any) => v.code !== 'DEMO');
    const getIsDemo = (): boolean => CookieService.get(ECookieVariable.USER_TRADE_COIN) === 'DEMO'

    return {
        toggleChangeTradeCoin: handleChangeTradeCoin,
        data,
        coinActive,
        coins: coinsArr,
        isDemo: coinActive.type === 'DEMO',
        getIsDemo
    }
}