import React, { FC } from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";

export interface IFiatWraperProps extends RouteComponentProps {}

export const withFiatWraper = (Component: FC<IFiatWraperProps>) => (props: IFiatWraperProps) => {
  return (
    <div className="withTransactionWraper">
      <div className="TabLinks">
        <NavLink to={Routes.DepositFiat.path} exact={true}>
          <Icon.Deposit />
          Deposit
        </NavLink>

        <NavLink to={Routes.WithdrawFiat.path} exact={true}>
          <Icon.Withdraw />
          Withdraw
        </NavLink>
      </div>

      <Component {...props} />
    </div>
  );
};
