import React, { FC } from "react";
import * as Yup from "yup";
import { InputNumber, InputText } from "../../../components";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, onError, useForm } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { BankService } from "../../../services";
import { useTradeCoinWithBalance } from "../../../hook";
// import "./index.scss";

export const TransferMainWallet: FC<{ data: any; onFinish: () => void }> = (props) => {
  const { coinActive } = useTradeCoinWithBalance();

  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: "email",
        defaultValue: props?.data?.email,
        validate: Yup.string().required(translate("must-be-provided")),
        isDisabled: true,
      },
      {
        name: "amount",
        validate: Yup.string().required(translate("must-be-provided")),
      },
    ],
    onSubmit: async (values) => {
      const params = {
        receiverEmail: props?.data?.email,
        value: values?.amount,
        coinId: coinActive?.coinId,
        feeCoinId: coinActive?.coinId,
        description: "Admin Transfer",
        pinCode: "",
      };

      return BankService.transferRequest(params)
        .then(() => {
          CreateAlert({
            type: "success",
            message: "Successful transfer",
          });
          props.onFinish();
        })
        .catch(onError);
    },
  });
  return (
    <form className="Edit" onSubmit={handleSubmit}>
      <InputWraper label={translate("email-address")} inputProps={getInputProps("email")} component={InputText} />

      <InputWraper label={translate("amount")} inputProps={getInputProps("amount")} component={InputNumber} />

      <div>
        <Button label="Update" isMiddle buttonType="success" className="btnBan" type="submit" />
      </div>
    </form>
  );
};
