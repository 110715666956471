const defaultState = {
    lifeCycleTime: 1,
    isServerInitialized: false,
    isClientInitialized: false,
    device: {},
    userPageInitializeStatus: 'pending',
    isHasPinCode: false,
    time: -1,
    serverTime: null,
}

export const SET_DEVICE = 'SET_DEVICE';
export const SET_USER_PAGE_INITIALIZE_STATUS = 'SET_USER_PAGE_INITIALIZE_STATUS';
export const INCREASE_APP_LIFE_CYCLE = 'INCREASE_APP_LIFE_CYCLE';
export const SET_IS_HAS_PIN_CODE = 'SET_IS_HAS_PIN_CODE';
export const SET_TIME = 'SET_TIME';
export const UPDATE_TIME = 'UPDATE_TIME';

export const mainReducer = (state = defaultState, action: any) => {
    const { type, data } = action;
    if (type === SET_DEVICE) return { ...state, device: { ...state.device, ...data } }
    if (type === SET_USER_PAGE_INITIALIZE_STATUS) return { ...state, userPageInitializeStatus: action.status }
    if (type === INCREASE_APP_LIFE_CYCLE) return { ...state, lifeCycleTime: state.lifeCycleTime + 1 }
    if (type === SET_IS_HAS_PIN_CODE) return { ...state, isHasPinCode: action.result }
    if (type === SET_TIME) return { ...state, time: action.time, serverTime: action.serverTime }
    if (type === UPDATE_TIME) {
        // @ts-ignore
        const newDateTime = new Date(state.serverTime + 1000)
        const newTime = state.time === 1 ? 60 : state.time - 1;
        return { ...state, time: newTime, serverTime: newDateTime.getTime() }
    }
    return state;
}