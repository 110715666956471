import React, { FC } from 'react'
import { Icon } from '../icon'
import { useHistory } from 'react-router-dom'

export const PageHead: FC<{ urlPath?: string, title: string }> = (props) => {
    const { push, goBack } = useHistory();

    return (
        <div className="PageHead">
            <div className="title">
                <div className="btnBack" onClick={() => props.urlPath ? push(props.urlPath) : goBack()}>
                    <Icon.ArrowLeft />
                </div>

                {props.title}
            </div>
        </div>
    )
}