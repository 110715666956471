
const defaultState = null

export const GET_WITHDRAW_CONFIG = 'GET_WITHDRAW_CONFIG';
export const GET_WITHDRAW_CONFIG_ERROR = 'GET_WITHDRAW_CONFIG_ERROR';
export const withdrawConfigReducer = (state = defaultState, action: any) => {
    const { type } = action;
    if (type === GET_WITHDRAW_CONFIG) return action.data
    if (type === GET_WITHDRAW_CONFIG_ERROR) return defaultState
    return state;
}