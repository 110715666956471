import React, { FC, useEffect, useRef } from 'react';
import { Button, Icon } from '../../modules';

export const GeneralPopupCompnt: FC<any> = (props) => {
    const popupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                props.onClose();
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef]);
    return (
        <div className="general-popup-compnt">
            <div className="popup-container">
                <div className="popup-wraper" ref={popupRef}>
                    <div className="btnClose" onClick={() => props?.onClose()}>
                        <Icon.ClosePopup />
                    </div>
                    <div className="popup-body">
                        {/* <img className="pic-confirm" src="/assets/images/popup-confirm-pic.png" alt="" /> */}
                        {props.titlePopup ? (
                            <div className="title-popup">
                                {' '}
                                {props.titlePopup}{' '}
                            </div>
                        ) : null}
                        <div className="message-popup">
                            {props.messagePopup}
                        </div>
                        {props?.contentPopup && (
                            <div className="content-popup">
                                
                            </div>
                        )}
                        {props?.children}
                        <div className="button-container">
                            <Button
                                label={'Confirm'}
                                type="submit"
                                buttonType="info"
                                className="confirm-button"
                                onClick={() => props?.onClickConfirm()}
                                isLoading={props.isLoading}
                                disabled={props.isLoading}
                            />
                            <Button
                                label={'Cancel'}
                                type="button"
                                buttonType="grey"
                                className="cancel-button"
                                onClick={() => props?.onClose()}
                                disabled={props.isLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
