const defaultState = null

export const SET_COIN_CONVERSION_RATE = 'SET_COIN_CONVERSION_RATE';

export const coinConversionRateReducer = (state = defaultState, action: any) => {
    const { type, data, error } = action;
    if (type === SET_COIN_CONVERSION_RATE) {
        if (error) return { error }
        if (data) return data;
    }
    return state;
}