import React from "react";
import * as Yup from "yup";
import { InputNumber } from "../../../components";
import { translate } from "../../../languages";
import { CreateAlert, InputWraper, onError, useForm } from "../../../modules";
import { ConfigService } from "../../../services/config/config.service";
import { store, useSelector } from "../../../store";
import { withConfigWraper } from "../wraper";
import RankConfigWithdraw from "./rankConfigWithdraw";
export const WithdrawConfig = withConfigWraper(() => {
  const withdrawConfig = useSelector((state) => state.withdrawConfig);
  const user = useSelector((state) => state.user);

  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: "userWithdrawMaxOnDay",
        defaultValue: Number(withdrawConfig?.USER_MAXIMUM_IN_DAY),
        validate: Yup.number()
          .required(translate("must be provided"))
          .min(1, translate("not filled in zero")),
      },
      {
        name: "systemWithdrawMaxOnDay",
        defaultValue: Number(withdrawConfig?.SYSTEM_MAXIMUM_IN_DAY),
        validate: Yup.number()
          .required(translate("must be provided"))
          .min(1, translate("not filled in zero")),
      },
    ],
    onSubmit: async (values) => {
      const valueConfig = {
        USER_MAXIMUM_IN_DAY: values.userWithdrawMaxOnDay,
        SYSTEM_MAXIMUM_IN_DAY: values.systemWithdrawMaxOnDay,
      };
      const payload = {
        isActiveScan: true,
        shouldProcessWithdraw: false,
        reservedEth: 0,
        withdrawRequestExpiration: 0,
        value: JSON.stringify(valueConfig),
        serverConfigName: "WITHDRAW_LIMIT_CONFIG",
      };
      return ConfigService.setConfig(payload)
        .then(async () => {
          CreateAlert({ type: "success", message: "Set config withdraw successful." });
          await ConfigService.getServerConfigWithdraw(store);
        })
        .catch(onError);
    },
  });

  return (
    <div className="mb20">
      <span className="config--title mt40 mb32">Withdraw Config</span>
      <div className="row config--item ml3 mr3">
        <div className="col-sm-5">
          <form className="blockLists" onSubmit={handleSubmit}>
            <InputWraper
              label={"User Withdraw Max On Day"}
              inputProps={getInputProps("userWithdrawMaxOnDay")}
              component={InputNumber}
            />
            <InputWraper
              label={"System Withdraw Max On Day"}
              inputProps={getInputProps("systemWithdrawMaxOnDay")}
              component={InputNumber}
            />
            {/* <div>
                <Button
                    // className="mb20"
                    label="Submit"
                    // onClick={props.onClick}
                    isMiddle
                    buttonType="success"
                    className="btnBan"
                    type="submit"
                />
            </div> */}
          </form>
        </div>
      </div>
      {user.userRole === "SUPER_ADMIN" && (
        <>
          <span className="config--title mt48 mb32">List Rank Config</span>
          <div className="row config--item ml3 mr3">
            <div className="col-sm-8">
              <RankConfigWithdraw />
            </div>
          </div>
        </>
      )}
    </div>
  );
});
