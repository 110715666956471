import React, { FC, useState, useEffect, memo } from "react";
import XLSX from "xlsx";

import { AdminService } from "../../../services/admin";
import { useSelector } from "../../../store";
import {
  Table,
  Button,
  Message,
  ITableStructureItem,
  ClassNames,
  ObjectUtils,
} from "../../../modules";
import { withFranchiseWraper } from "../wraper";
import { getLocaleKey } from "../../../languages";
import {
  TableFilterInputText,
  InputDateTimeRange,
} from "../../../components/table-filter-inputs";
import { PopupWraper } from "../../../components/popup";

export const PageFranchiseLeaderTable = withFranchiseWraper(
  memo(() => {
    const user = useSelector((state) => state.user);
    const [currentData, setCurrentData] = useState(null as any);
    const [userReport, setUserReport] = useState(null as any);

    return (
      <div className="PageFranchiseLeaderTable">
        <FranchiseLeaderUserReportPopup
          userData={userReport}
          onClose={() => setUserReport(null)}
        />

        <Button
          disabled={!currentData}
          label="Export to Excel"
          className="mb15"
          buttonType="success"
          onClick={() => {
            const data = [
              [
                "User",
                "Won Volume",
                "Lose Volume",
                "Deposit",
                "Withdraw",
                "Balance",
                "Trading Commission",
                "Direct Commission",
                "Cashback",
              ],
              ...currentData.full.map((item: any) => {
                let row: any[] = [];
                row.push(item.email);
                row.push(
                  `$${(+item.wonVolume).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}`
                );
                row.push(
                  item.lostVolume
                    ? `-$${(+item.lostVolume).toLocaleString(getLocaleKey(), {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}`
                    : 0
                );
                row.push(
                  `ETH: ${(+item.depositEth).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 8,
                    minimumFractionDigits: 8
                  })} USDT: ${(+item.depositUsdt).toLocaleString(
                    getLocaleKey(),
                    { maximumFractionDigits: 8, minimumFractionDigits: 8 }
                  )}`
                );
                row.push(
                  `ETH: ${(+item.withdrawEth).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 8,
                    minimumFractionDigits: 8
                  })} USDT: ${(+item.withdrawUsdt).toLocaleString(
                    getLocaleKey(),
                    { maximumFractionDigits: 8, minimumFractionDigits: 8 }
                  )}`
                );
                row.push(
                  `ETH: ${(+item.balanceEth).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 8,
                    minimumFractionDigits: 8
                  })} USDT: ${(+item.balanceUsdt).toLocaleString(
                    getLocaleKey(),
                    { maximumFractionDigits: 8, minimumFractionDigits: 8 }
                  )} USD: ${(+item.balanceUsd).toLocaleString(
                    getLocaleKey(),
                    { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                  )}`
                );
                row.push(item.rankCommission);
                row.push(item.directCommission);
                row.push(item.cashback);

                return row;
              }),
            ];

            const ws = XLSX.utils.aoa_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

            const now = new Date();
            XLSX.writeFile(
              wb,
              `Franchise ${now
                .toLocaleDateString()
                .replace(/\//g, "-")} ${now
                .toLocaleTimeString()
                .replace(/:/g, "-")}.xlsx`,
              { type: "binary" }
            );
          }}
        />

        <Table
          className="overload"
          filters={[
            {
              name: "User",
              key: "q",
              input: TableFilterInputText,
            },
          ]}
          structure={[
            {
              name: "User",
              key: "q",
              render: (item) => {
                let fullname = item.firstName + " " + item.lastName;
                return (
                  <div className="link" onClick={() => setUserReport(item)}>
                    {fullname} <br />
                    {item.email} <br />
                  </div>
                );
              },
            },
            {
              name: "Won volume",
              key: "wonVolume",
              className: "textSuccess",
              render: (item) => {
                return `$${(+item.wonVolume).toLocaleString(getLocaleKey(), {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}`;
              },
              sort: {},
            },
            {
              name: "Lose volume",
              key: "lostVolume",
              className: "textDanger",
              render: (item) => {
                const value = +item.lostVolume;
                return value
                  ? `-$${(+item.lostVolume).toLocaleString(getLocaleKey(), {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}`
                  : 0;
              },
              sort: {},
            },
            {
              name: "Deposit",
              render: (item) => {
                return (
                  <span>
                    ETH:{" "}
                    {(+item.depositEth).toLocaleString(getLocaleKey(), {
                      maximumFractionDigits: 8,
                      minimumFractionDigits: 8
                    })}{" "}
                    <br />
                    USDT:{" "}
                    {(+item.depositUsdt).toLocaleString(getLocaleKey(), {
                      maximumFractionDigits: 8,
                      minimumFractionDigits: 8
                    })}
                  </span>
                );
              },
            },
            {
              name: "Withdraw",
              render: (item) => {
                return (
                  <span>
                    ETH:{" "}
                    {(+item.withdrawEth).toLocaleString(getLocaleKey(), {
                      maximumFractionDigits: 8,
                      minimumFractionDigits: 8
                    })}{" "}
                    <br />
                    USDT:{" "}
                    {(+item.withdrawUsdt).toLocaleString(getLocaleKey(), {
                      maximumFractionDigits: 8,
                      minimumFractionDigits: 8
                    })}
                  </span>
                );
              },
            },
            {
              name: "Balance",
              render: (item) => {
                return (
                  <span>
                    ETH:{" "}
                    {(+item.balanceEth).toLocaleString(getLocaleKey(), {
                      maximumFractionDigits: 8,
                      minimumFractionDigits: 8
                    })}{" "}
                    <br />
                    USDT:{" "}
                    {(+item.balanceUsdt).toLocaleString(getLocaleKey(), {
                      maximumFractionDigits: 8,
                      minimumFractionDigits: 8
                    })}
                     <br />
                    USD:{" "}
                    {(+item.balanceUsd).toLocaleString(getLocaleKey(), {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </span>
                );
              },
            },
            {
              name: "Trading Commission",
              key: "rankCommission",
              render: (item) => {
                return `${(+item.rankCommission).toLocaleString(
                  getLocaleKey(),
                  { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                )}`;
              },
              sort: {},
            },
            {
              name: "Direct Commission",
              key: "directCommission",
              render: (item) => {
                return `${(+item.directCommission).toLocaleString(
                  getLocaleKey(),
                  { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                )}`;
              },
              sort: {},
            },
            {
              name: "Cashback",
              key: "cashBack",
              render: (item) => {
                return `${(+item.cashBack).toLocaleString(getLocaleKey(), {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}`;
              },
              sort: {},
            },
          ]}
          fetchData={async (params) =>
            AdminService.getFranchiseLeaderReport(100, user.userId, params).then(
              (res) => {
                setCurrentData(res);
                return res;
              }
            )
          }
        />
      </div>
    );
  })
);

export const FranchiseLeaderUserReportPopup: FC<{
  userData: any;
  onClose: () => void;
}> = (props) => {
  const [userReport, setUserReport] = useState(null as any);
  const userId = ObjectUtils.getIn(props, "userData.userId");

  const [rangeTime, setRangeTime] = useState({
    startTime: Date.now() - 1000 * 60 * 60 * 24 * 29,
    endTime: Date.now(),
  } as any);

  useEffect(() => {
    if (userId) {
      setUserReport(null);

      AdminService.getFanshiseLeaderUserReport(userId, {
        fromDate: new Date(rangeTime.startTime),
        toDate: new Date(rangeTime.endTime),
      })
        .then((res) => {
          const sumReports = Object.keys(res[0]).reduce(
            (ouput: any, key: string) => {
              if (typeof res[0][key] === "number")
                ouput[key] = res.reduce(
                  (sum: number, item: any) => sum + item[key],
                  0
                );
              return ouput;
            },
            {}
          );

          setUserReport({
            count: res.length,
            data: res,
            sumReports,
          });
        })
        .catch((err) => setUserReport({ error: err.error }));
    }
  }, [userId, rangeTime]);

  const sumMiddleware = (func: (item: any) => any) => (
    item: any,
    fetchData: any,
    column: ITableStructureItem
  ) => {
    if (item.isSum) {
      if (column.key === "startAt") return "SUM";
      if (
        column.key &&
        userReport &&
        userReport.sumReports &&
        typeof userReport.sumReports[column.key] === "number"
      ) {
        if (Number.isNaN(userReport.sumReports[column.key])) return "--";
        const value = +userReport.sumReports[column.key] || 0;
        if (column.key === "profit")
          return (
            <span
              className={ClassNames({
                textDanger: value < 0,
                textSuccess: value > 0,
              })}
            >
              {value.toLocaleString(getLocaleKey(), {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </span>
          );
        return `${value.toLocaleString(getLocaleKey(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}`;
      }

      return "--";
    }

    return func(item);
  };

  const structure: ITableStructureItem[] = [
    {
      name: "Date",
      key: "startAt",
      render: sumMiddleware((item) =>
        new Date(item.startAt).toLocaleDateString(getLocaleKey(), {
          hour12: false,
        })
      ),
      sort: {},
    },
    {
      name: "Won volume",
      key: "wonVolume",
      render: sumMiddleware((item) =>
        (+item.wonVolume).toLocaleString(getLocaleKey(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })
      ),
      sort: {},
    },
    {
      name: "Lose volume",
      key: "lostVolume",
      render: sumMiddleware((item) =>
        (+item.lostVolume).toLocaleString(getLocaleKey(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })
      ),
      sort: {},
    },
    {
      name: "Trading commission",
      key: "rankCommission",
      render: sumMiddleware((item) =>
        (+item.rankCommission).toLocaleString(getLocaleKey(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })
      ),
      sort: {},
    },
    {
      name: "Direct commission",
      key: "directCommission",
      render: sumMiddleware((item) =>
        (+item.directCommission).toLocaleString(getLocaleKey(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })
      ),
      sort: {},
    },
    {
      name: "Profit",
      key: "profit",
      render: sumMiddleware((item) => (
        <span
          className={ClassNames({
            textDanger: item.profit < 0,
            textSuccess: item.profit > 0,
          })}
        >
          {(+item.profit).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })}
        </span>
      )),
      sort: {},
    },
  ];

  if (!props.userData) return null;

  return (
    <PopupWraper title="User report" onClose={props.onClose}>
      <div className="userInfo textWhite mb15">
        <h4>
          {props.userData.firstName} {props.userData.lastName} -{" "}
          {props.userData.email}
        </h4>
      </div>

      <div className="SystemReport__Head ml0 mr0">
        <div className="item">
          <div className="label">Range time</div>
          <InputDateTimeRange
            onChange={(e) => (e ? setRangeTime(e) : null)}
            startTimeDefaultValue={rangeTime?.startTime}
            endTimeDefaultValue={rangeTime?.endTime}
          />
        </div>

        <Button
          disabled={!userReport || userReport.error}
          label="Export to Excel"
          className="mb15"
          buttonType="success"
          onClick={() => {
            const data = [
              structure.map((v) => v.name),
              ...userReport.data.map((item: any) => {
                return structure.map((v, index) =>
                  index === 0 && v.render
                    ? v.render(item, () => false, v)
                    : item[v.key || ""]
                );
              }),
            ];

            const ws = XLSX.utils.aoa_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

            const now = new Date();
            XLSX.writeFile(
              wb,
              `Franchise User Report ${now
                .toLocaleDateString()
                .replace(/\//g, "-")} ${now
                .toLocaleTimeString()
                .replace(/:/g, "-")}.xlsx`,
              { type: "binary" }
            );
          }}
        />
      </div>

      {(() => {
        if (!userReport) return <Message type="loading" />;
        return (
          <Table
            enableReinitialize
            className="overload hideFooter hasSUM"
            structure={structure}
            fetchData={async (params) => {
              let data = [...userReport.data];

              structure.map((v) => {
                if (v.sort) {
                  const key = v.key || "";
                  if (params[key] === "increase")
                    data = data.sort((a: any, b: any) => a[key] - b[key]);
                  if (params[key] === "descrease")
                    data = data.sort((a: any, b: any) => b[key] - a[key]);
                }

                return v;
              });

              return {
                data: [{ isSum: true }, ...data],
                count: data.length,
              };
            }}
          />
        );
      })()}
    </PopupWraper>
  );
};
