import React, { FC } from "react";
import * as Yup from "yup";

import { InputNumber } from "../input";
import {
  Button,
  useForm,
  InputWraper,
  CreateAlert,
  onError,
} from "../../modules";
import { AdminService } from "../../services/admin";
import { useState } from "react";

export const InterventionLimit: FC = () => {
  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "interventionLimit",
        label: "Amount",
        validate: Yup.number().required("Must be provided."),
      },
    ],
    onSubmit: async (values) => {
      return AdminService.interventionLimit(values.interventionLimit)
        .then(() => CreateAlert({ type: "success", message: "Successful!" }))
        .catch(onError);
    },
  });
  const [currentIntervention, setCurrentIntervention] = useState(0);

  AdminService.getBudgetPercent().then((newData) => {
    setCurrentIntervention(newData);
  });
  return (
    <form onSubmit={handleSubmit} className="InterventionLimit">
      <div className="title">Intervention limit</div>
      <label>Current Intervention limit: {currentIntervention}</label>
      <InputWraper
        inputProps={getInputProps("interventionLimit")}
        component={InputNumber}
      />
      <Button isLoading={isSubmitting} type="submit" label="Submit" />
    </form>
  );
};
