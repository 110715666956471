import React, { FC } from "react";
import { useTradeCoinWithBalance } from "../../hook";
import { getLocaleKey } from "../../languages";
import { ClassNames } from "../../modules";
import { useSelector } from "../../store";
import { Icon } from "../icon";
import { Image } from "../image";

export const UserHead: FC = () => {
  const auth = useSelector((state) => state.user);

  if (!auth) return null;

  return (
    <div className="UserHead">
      <div className="profile">
        <Image src={auth.avatar} type="avatar" />

        <div className="info">
          <div className="name">{auth.userNameLogin}</div>
          <div className="email">{auth.email}</div>
        </div>
      </div>

      {/* <div className="ctas">
            <div className="mr15">
                <SettingBox />
            </div>
            <div className="mr15">
                <SettingMode />
            </div>
            <ButtonSwitchSymbol />
            <ButtonSwitchAccount />
        </div> */}
    </div>
  );
};

// const ButtonSwitchSymbol: FC = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   useAppLifeCycle();

//   const symbolsState: any[] = useSelector((state) => state.symbols);
//   const symbols: any[] = ObjectUtils.getIn(symbolsState, "data", []);

//   const symbolActiveName = TradeService.getSymbolActiveName();
//   const currentSymbolActiveDescription = ObjectUtils.getIn(
//     symbols.find((v: any) => v.name === symbolActiveName),
//     "description",
//     "--"
//   );

//   return (
//     <button
//       type="button"
//       className="btn dropdowns"
//       onMouseEnter={() => setIsOpen(true)}
//       onMouseLeave={() => setIsOpen(false)}
//       onClick={() => setIsOpen((state) => !state)}
//     >
//       <div className="info">
//         <strong>{currentSymbolActiveDescription}</strong>
//       </div>

//       <div className="toggleIcon">
//         <Icon.ArrowDown />
//       </div>

//       {isOpen ? (
//         <div className="options">
//           <div className="wraper">
//             {symbols.map((item, key) => {
//               return (
//                 <div
//                   key={key}
//                   className={ClassNames({
//                     item: true,
//                     active: item.name === symbolActiveName,
//                   })}
//                   onClick={() => {
//                     TradeService.setSymbolActive(item);
//                     setTimeout(() => setIsOpen(false), 20);
//                   }}
//                 >
//                   <div className="name">{item.description}</div>
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       ) : null}
//     </button>
//   );
// };

export const ButtonSwitchAccount: FC<{ onChange?: () => void }> = (props) => {
  const {
    data,
    toggleChangeTradeCoin: handleChangeTradeCoin,
    coinActive,
  } = useTradeCoinWithBalance();

  return (
    <button type="button" className="btn dropdowns">
      <div className="info">
        <div className="label">{coinActive.label}</div>
        <div className="amount">
          $
          {coinActive.amount?.toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </div>
      </div>

      <div className="toggleIcon">
        <Icon.ArrowDown />
      </div>

      <div className="options">
        <div className="wraper">
          {data.map((item, key) => {
            const isActive = item.isActive;

            return (
              <div
                key={key}
                className={ClassNames({
                  item: true,
                  active: item.isActive,
                })}
                onClick={() => {
                  if (!isActive) {
                    handleChangeTradeCoin();
                    props.onChange && props.onChange();
                  }
                }}
              >
                <div className="type">{item.label}</div>
                <div className="amount">
                  $
                  {(+item.amount).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </button>
  );
};
