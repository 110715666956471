import React, { FC, memo } from 'react'

import DropdownTreeSelect, { TreeNode, Mode } from 'react-dropdown-tree-select'
import { IInputProps, ObjectUtils, ClassNames, isEqual } from '../../../modules'
import { unflatTreeArr } from './utils' 

interface IInputSelecctTree extends IInputProps {
    flatData: any[],
    mode?: Mode
}

export const InputSelectTree: FC<IInputSelecctTree> = memo((props) => {
    const getOptions = () => {
        let temp: any[] = ObjectUtils.getIn(props, 'flatData', []);
        if (props.mode === 'multiSelect' || props.mode === 'hierarchical') {
            temp = temp.map((item) => ({
                ...item,
                checked: props.defaultValue.includes(item.value)
            }))
        } else {
            temp = temp.map((item) => ({ ...item, checked: item.id === props.value }))
        }

        return unflatTreeArr(temp)
    }
    const data = getOptions();

    const onChange = (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
        if (props.mode === 'multiSelect' || props.mode === 'hierarchical') {
            props.onChange(selectedNodes.map(v => v.value));
        } else {
            if (!currentNode.checked) props.onChange('');
            else props.onChange(currentNode.value);
        }
    }

    const isHasValue = data.find(item => item.value === props.value);

    return (
        <DropdownTreeSelect
            className={ClassNames({ InputSelectTree: true, hasValue: isHasValue })}
            data={data}
            onChange={onChange}
            onBlur={() => setTimeout(() => props.onTouched(), 500)}
            mode={props.mode}
            inlineSearchInput={true}
            texts={{
                placeholder: '-- Select --'
            }}
        />
    )
}, (prevProps, nextProps) => {
    return isEqual(prevProps.defaultValue, nextProps.defaultValue)
        && isEqual(prevProps.flatData, nextProps.flatData)
})

InputSelectTree.defaultProps = {
    mode: 'radioSelect'
}