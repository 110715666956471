import React, { FC, useEffect, Fragment } from "react";

import { useSelector } from "../../store";
import { Routes } from "../../AppRoutes";
import { useHistory } from "react-router-dom";

export const AuthWraper: FC = (props) => {
    const { push } = useHistory();
    const auth = useSelector((state) => state.user);

    useEffect(() => {
        if (auth) {
            push(
                localStorage.getItem("auth-redirect-to") || Routes.userList.path
            );
            localStorage.removeItem("auth-redirect-to");
        }
    }, [auth, push]);

    return <Fragment>{props.children}</Fragment>;
};
