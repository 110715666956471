import React, { FC, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import {
  ObjectUtils,
  useForm,
  InputWraper,
  Button,
  CreateAlert,
  onError,
} from "../../../modules";
import { AdminService } from "../../../services/admin";
import { PageHead } from "../../../components/page-head";
import { InputNumber } from "../../../components";

export const UserDetail: FC = () => {
  // const [data, setData] = useState(null as any);
  const { params } = useRouteMatch();
  const id = +ObjectUtils.getIn(params, "email", "");

  useEffect(() => {
    // AdminService.getUserDetail(email)
    //     .then(res => setData(res))
    //     .catch(err => setData({ error: err.error }))
  }, [id]);

  return (
    <div className="UserDetail">
      <PageHead title="User Detail" />

      {/* {(() => {
                if (!data) return <Message type="loading" />
                if (data.error) return <Message type="error" message={data.error.message} />

                const { userInfo } = data;

                return <Fragment>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="box">
                                <div className="head">User Info</div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Email</td>
                                            <td>{userInfo.email || '--'}</td>
                                        </tr>
                                        <tr>
                                            <td>First name</td>
                                            <td>{userInfo.firstName || '--'}</td>
                                        </tr>
                                        <tr>
                                            <td>Last name</td>
                                            <td>{userInfo.lastName || '--'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        
                    </div>
                </Fragment>
            })()} */}

      {/* <div className="row">
        <div className="col-sm-4">
          <div className="box">
            <div className="head">Set Rank</div>
            <SetRank userId={id} />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export const SetRank: FC<{ userId: number }> = (props) => {
  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "rank",
      },
    ],
    onSubmit: async (values) => {
      return AdminService.setRank(props.userId, values.rank)
        .then(() =>
          CreateAlert({ type: "success", message: "Set rank successful." })
        )
        .catch(onError);
    },
  });

  return (
    <form onSubmit={handleSubmit} className="pd15">
      <InputWraper
        inputProps={getInputProps("rank")}
        renderInput={InputNumber}
      />

      <Button isLoading={isSubmitting} label="Set Rank" type="submit" />
    </form>
  );
};
