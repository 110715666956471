const defaultState = null

export const SET_COUNTRIES = 'SET_COUNTRIES';

export const countriesReducer = (state = defaultState, action: any) => {
    const { type, data, error } = action;
    if (type === SET_COUNTRIES) {
        if (error) return { error }
        if (data) return data;
    }
    return state;
}