import React, { FC, useState, Fragment } from 'react'

import { IInputProps, Icon } from '../../../modules'

export const InputPassword: FC<IInputProps> = (props) => {
    const [isVisiblePassword, setIsVisiblePassword] = useState(false)

    return (
        <Fragment>
            <input
                className="InputPassword"
                value={props.value || ''}
                readOnly={props.isDisabled}
                type={isVisiblePassword ? 'text' : 'password'}
                onFocus={() => props.onTouched()}
                onChange={e => props.onChange(e.target.value)}
                autoComplete="off"
            />

            {props.value ? <div className="InputPassword_ToggleVisible" onClick={() => setIsVisiblePassword(state => !state)}>
                {isVisiblePassword ? <Icon.Invisible /> : <Icon.Visible />}
            </div> : null}
        </Fragment>
    )
}