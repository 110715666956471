import React, { useState, useEffect, FC, memo } from "react";
import XLSX from "xlsx";

import { moduleConfig } from "../../../modules/module.config";
import { withReportWraper } from "../wraper";
import { AdminService } from "../../../services/admin";
import { InputDateTimeRange } from "../../../components/table-filter-inputs";
import { Message, Table, Button, ObjectUtils, CreateAlert, ITableStructureItem, NumberUtils } from "../../../modules";
import { getLocaleKey } from "../../../languages";
import { Icon, InputSelect } from "../../../components";
import moment from "moment";
import { getEnv } from "../../../configs";
import { useSelector } from "../../../store";

export const ReportSystem = withReportWraper(
  memo(
    () => {
      const [rangeTime, setRangeTime] = useState({
        startTime: moment().startOf("month").format("YYYY/MM/DD HH:mm:ss"), //Date.now() - 1000 * 60 * 60 * 24 * 30,
        endTime: moment().endOf("day").format("YYYY/MM/DD HH:mm:ss"), //Date.now(),
      } as any);

      const [reports, setReports] = useState(null as any);
      const [systemReportDetail, setSystemReportDetail] = useState(null as any);
      const [modeOption, setModeOption] = useState("");

      const user = useSelector((state) => state.user);

      useEffect(() => {
        if (!rangeTime) {
          setReports(null);
        } else {
          setReports(null);

          if (user.userRole === "AGENT")
            AdminService.getSytemReportAgent({
              fromDate: new Date(rangeTime.startTime),
              toDate: new Date(rangeTime.endTime),
            })
              .then((res) => {
                const sumReports = Object.keys(res[0]).reduce((ouput: any, key: string) => {
                  //if (typeof res[0][key] === 'number') ouput[key] = res.reduce((sum: number, item: any) => sum + item[key], 0);
                  ouput[key] = res.reduce((sum: number, item: any) => sum + item[key], 0);
                  return ouput;
                }, {});

                setReports({
                  count: res.length,
                  data: res.sort((a: any, b: any) => b.start - a.start),
                  sumReports,
                });
              })
              .catch((err) => setReports({ error: err.error }));
          else
            AdminService.getSytemReport({
              fromDate: new Date(rangeTime.startTime),
              toDate: new Date(rangeTime.endTime),
            })
              .then((res) => {
                const sumReports = Object.keys(res[0]).reduce((ouput: any, key: string) => {
                  //if (typeof res[0][key] === 'number') ouput[key] = res.reduce((sum: number, item: any) => sum + item[key], 0);
                  ouput[key] = res.reduce((sum: number, item: any) => sum + item[key], 0);
                  return ouput;
                }, {});

                setReports({
                  count: res.length,
                  data: res.sort((a: any, b: any) => b.start - a.start),
                  sumReports,
                });
              })
              .catch((err) => setReports({ error: err.error }));
        }
      }, [rangeTime, user.userRole]);

      const ModeOptions = [
        { label: "Balance", value: "userBalance" },
        { label: "Hot Wallet", value: "hotWallet" },
        { label: "Convert", value: "convert" },
        // { label: "Withdraw", value: "withdraw" },
        { label: "Deposit", value: "deposit" },
        // { label: "Safe", value: "safe" },
      ];

      const sumMiddleware = (func: (item: any) => any) => (item: any, fetchData: any, column: ITableStructureItem) => {
        if (item.isSum) {
          if (column.key === "date") return "SUM";
          if (column.key === "userCount") return reports?.data[0]?.userCount;
          if (column.key === "userBalanceUsdt")
            return NumberUtils.toFormatNumber(reports?.data[0]?.userBalanceUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          if (column.key === "totalHotWallet")
            return NumberUtils.toFormatNumber(reports?.data[0]?.hotWalletUsdt + reports?.data[0]?.fiatHotWallet, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          // if (column.key === "hotWalletUsdtBep20")
          //   return NumberUtils.toFormatNumber(reports?.data[0]?.hotWalletUsdtBep20, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          // if (column.key === "hotWalletUsdtTrc20")
          //   return NumberUtils.toFormatNumber(reports?.data[0]?.hotWalletUsdtTrc20, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          if (column.key === "hotWalletUsdt") return NumberUtils.toFormatNumber(reports?.data[0]?.hotWalletUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          if (column.key === "fiatHotWallet") return NumberUtils.toFormatNumber(reports?.data[0]?.fiatHotWallet, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          // if (column.key === "depositUsdt")
          //   return NumberUtils.toFormatNumber(
          //     reports?.data[0]?.depositUsdt,
          //     +getEnv("NUMBER_DECIMAL_DISPLAY")
          //   );
          // if (column.key === "depositUsdtBep20")
          //   return NumberUtils.toFormatNumber(reports?.data[0]?.depositUsdtBep20, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          // if (column.key === "depositUsdtTrc20")
          //   return NumberUtils.toFormatNumber(reports?.data[0]?.depositUsdtTrc20, +getEnv("NUMBER_DECIMAL_DISPLAY"));

          // if (column.key === "withdrawUsdt")
          //   return NumberUtils.toFormatNumber(
          //     reports?.data[0]?.withdrawUsdt,
          //     +getEnv("NUMBER_DECIMAL_DISPLAY")
          //   );
          // if (column.key === "withdrawUsdtBep20")
          //   return NumberUtils.toFormatNumber(reports?.data[0]?.withdrawUsdtBep20, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          // if (column.key === "withdrawUsdtTrc20")
          //   return NumberUtils.toFormatNumber(reports?.data[0]?.withdrawUsdtTrc20, +getEnv("NUMBER_DECIMAL_DISPLAY"));

          if (column.key === (user.userRole === "AGENT" ? "userBalanceMega" : "userBalanceGp2"))
            return NumberUtils.toFormatNumber(
              user.userRole === "AGENT" ? reports?.data[0]?.userBalanceMega : reports?.data[0]?.userBalanceGp2,
              +getEnv("NUMBER_DECIMAL_DISPLAY")
            );
          if (column.key === "hotWalletGp2") return NumberUtils.toFormatNumber(reports?.data[0]?.hotWalletGp2, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          if (column.key && reports && reports.sumReports && typeof reports.sumReports[column.key] === "number") {
            if (Number.isNaN(reports.sumReports[column.key])) return "--";
            const value = NumberUtils.toFormatNumber(+reports.sumReports[column.key] || 0, +getEnv("NUMBER_DECIMAL_DISPLAY"));
            if (["userCount", "userOrderCount", "orderCount", "loginCount"].includes(column.key))
              return `${(+reports.sumReports[column.key] || 0).toLocaleString(getLocaleKey(), {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              })}`;
            if (["Company", "Net Profit"].find((item) => item === column.name))
              return (
                <span
                  className={
                    reports.sumReports[column.key] > 0
                      ? "profit-volume-red"
                      : reports.sumReports[column.key] < 0
                      ? "profit-volume-green"
                      : "profit-volume-yellow"
                  }
                >
                  {NumberUtils.toFormatNumber(Math.abs(+reports.sumReports[column.key]) || 0, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
                </span>
              );
            if (["Profit Volume", "Profit Marketing"].find((item) => item === column.name))
              return (
                <span
                  className={
                    reports.sumReports[column.key] > 0
                      ? "profit-volume-green"
                      : reports.sumReports[column.key] < 0
                      ? "profit-volume-red"
                      : "profit-volume-yellow"
                  }
                >
                  {value}
                </span>
              );
            return `${value}`;
          }

          return "--";
        }

        return func(item);
      };

      let structure: any = [
        {
          name: "Date",
          key: "date",
          render: sumMiddleware((item) => {
            return <div>{moment(item?.start).format("DD/MM/y")}</div>;
          }),
          sort: {
            key: "date",
          },
        },
        {
          name: "Total Volume",
          key: "orderVolume",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.orderVolume, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "orderVolume",
          },
        },
        {
          name: "Marketing Volume",
          key: "mktVolume",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.mktVolume, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "mktVolume",
          },
        },
        {
          name: "Team Commission",
          key: "teamCommission",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.teamCommission, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "teamCommission",
          },
        },
        {
          name: "Agent Commission",
          key: "agentCommission",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.agentCommission, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "agentCommission",
          },
        },
        {
          name: "Refund",
          key: "refundValue",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.refundValue, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "refundValue",
          },
        },
        // {
        //   name: "Refund USDT",
        //   key: "refundValue",
        //   render: sumMiddleware((item: any) => {
        //     return NumberUtils.toFormatNumber(+item?.refundValue, +getEnv("NUMBER_DECIMAL_DISPLAY"));
        //   }),
        //   sort: {
        //     key: "refundValue",
        //   },
        // },
        {
          name: "Company",
          key: "profitVolume",
          render: sumMiddleware((item: any) => {
            return (
              <span
                className={item?.profitVolume > 0 ? "profit-volume-red" : item?.profitVolume < 0 ? "profit-volume-green" : "profit-volume-yellow"}
              >
                {NumberUtils.toFormatNumber(Math.abs(+item?.profitVolume), +getEnv("NUMBER_DECIMAL_DISPLAY"))}
              </span>
            );
          }),
          sort: {
            key: "profitVolume",
          },
        },
        {
          name: "Net Profit",
          key: "netProfit",
          render: sumMiddleware((item: any) => {
            return (
              <span className={item?.netProfit === 0 ? "profit-volume-yellow" : item?.netProfit > 0 ? "profit-volume-green" : "profit-volume-red"}>
                {NumberUtils.toFormatNumber(Math.abs(+item?.netProfit), +getEnv("NUMBER_DECIMAL_DISPLAY"))}
              </span>
            );
          }),
          sort: {
            key: "netProfit",
          },
        },
        {
          name: "Total User Balance",
          key: "userBalanceUsdt",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.userBalanceUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "userBalanceUsdt",
          },
        },
        {
          name: "Total Deposit",
          key: "depositUsdt",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.depositUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "depositUsdt",
          },
        },
        {
          name: "Total Withdraw",
          key: "withdrawUsdt",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.withdrawUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "withdrawUsdt",
          },
        },
        {
          name: "Total Hot Wallet",
          key: "totalHotWallet",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.hotWalletUsdt + +item?.fiatHotWallet, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "totalHotWallet",
          },
        },
        {
          name: "Crypto Hot Wallet",
          key: "hotWalletUsdt",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.hotWalletUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "hotWalletUsdt",
          },
        },
        {
          name: "Fiat Hot Wallet",
          key: "fiatHotWallet",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.fiatHotWallet, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "fiatHotWallet",
          },
        },
        {
          name: "Fiat Deposit",
          key: "depositFiat",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.depositFiat, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "depositFiat",
          },
        },
        {
          name: "Fiat Withdraw",
          key: "withdrawFiat",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.withdrawFiat, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "withdrawFiat",
          },
        },
        {
          name: "Users",
          key: "userCount",
          render: sumMiddleware((item: any) => {
            return (+item.userCount).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            });
          }),
          sort: {
            key: "userCount",
          },
        },
        {
          name: "Total User Orders",
          key: "userOrderCount",
          render: sumMiddleware((item: any) => {
            return (
              <span className="orders-overview-clickable">
                {(+item.userOrderCount).toLocaleString(getLocaleKey(), {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}
              </span>
            );
          }),
          sort: {
            key: "userOrderCount",
          },
        },
        {
          name: "Deposit Bonus",
          key: "depositBonus",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.depositBonus, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "depositBonus",
          },
        },
        {
          name: "Lose 7",
          key: "lose7",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.lose7, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "lose7",
          },
        },
        {
          name: "Lose 9",
          key: "lose8",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.lose8, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "lose8",
          },
        },
        {
          name: "Total Orders",
          key: "orderCount",
          render: sumMiddleware((item: any) => {
            return (+item.orderCount).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            });
          }),
          sort: {
            key: "orderCount",
          },
        },
        {
          name: "Login Count",
          key: "loginCount",
          render: sumMiddleware((item: any) => {
            return (+item.loginCount).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            });
          }),
          sort: {
            key: "loginCount",
          },
        },
      ];

      if (user.userRole !== "AGENT")
        structure = [
          ...structure,
          {
            name: "Transfer USDT",
            key: "transferUsdt",
            render: sumMiddleware((item: any) => {
              return NumberUtils.toFormatNumber(+item?.transferUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));
            }),
            sort: {
              key: "transferUsdt",
            },
          },
          {
            name: "Recovery USDT",
            key: "recoveryUsdt",
            render: sumMiddleware((item: any) => {
              return NumberUtils.toFormatNumber(+item?.recoveryUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));
            }),
            sort: {
              key: "recoveryUsdt",
            },
          },
        ];

      structure = [
        ...structure,
        {
          name: "Profit Volume",
          key: "profitVolume",
          className: "profit-volume",
          render: sumMiddleware((item: any) => {
            return (
              <span className={item?.profitVolume > 0 ? "profit-volume-green" : item?.profitVolume < 0 ? "profit-volume-red" : ""}>
                {NumberUtils.toFormatNumber(+item?.profitVolume, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
              </span>
            );
          }),
          sort: {
            key: "profitVolume",
          },
        },
        {
          name: "Profit Marketing",
          key: "mktProfit",
          render: sumMiddleware((item: any) => {
            return (
              <span className={item?.mktProfit > 0 ? "profit-volume-green" : item?.mktProfit < 0 ? "profit-volume-red" : ""}>
                {NumberUtils.toFormatNumber(+item?.mktProfit, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
              </span>
            );
          }),
          sort: {
            key: "mktProfit",
          },
        },
      ];

      structure = structure.filter((v: any) => {
        if (modeOption === "hotWallet") return ["date", "hotWalletGp2", "hotWalletUsdt"].includes(v.key);
        if (modeOption === "userBalance")
          return ["date", user.userRole === "AGENT" ? "userBalanceMega" : "userBalanceGp2", "totalBalanceUSD"].includes(v.key);
        // if (modeOption === "convert") return ["date", "convertRecieved"].includes(v.key);
        if (modeOption === "deposit") return ["date", "depositUsdt", "depositMega"].includes(v.key);
        return true;
      });

      const handleExportExcel = async () => {
        return new Promise((resolve) => {
          try {
            const reportData = ObjectUtils.getIn(reports, "data", []);
            const fileHead = structure.map((v: any) => v.name);
            const data = reportData.map((item: any) =>
              structure.map((column: any, index: number) => {
                if (index === 0) return moment(item?.start).format("DD/MM/y");
                if (
                  [
                    "orderVolume",
                    "profitVolume",
                    "netProfit",
                    "totalBalanceUSD",
                    user.userRole === "AGENT" ? "userBalanceMega" : "userBalanceGp2",
                    "totalHotWallet",
                    "fiatHotWallet",
                    "withdrawFiat",
                    "depositFiat",
                    "hotWalletUsdt",
                    "depositUsdt",
                    "depositUsdtBep20",
                    "depositUsdtTrc20",
                    "withdrawUsdt",
                    "withdrawUsdtBep20",
                    "withdrawUsdtTrc20",
                    // "refundValue",
                    "exchangeIn",
                    "exchangeOut",
                    "exchangeOutFeeTotal",
                    // "convertRecieved",
                    "transferUsdt",
                    "recoveryUsdt",
                    "teamCommission",
                    "mktVolume",
                    "mktProfit",
                    "agentCommission",
                    "refundValue",
                    "depositBonus",
                    "lose7",
                    "lose8",
                  ].includes(column?.key)
                )
                  return item[column.key] ? NumberUtils.toFormatNumber(+item[column.key], +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`;
                return item[column.key] || 0;
              })
            );

            const dataExport = [
              fileHead,
              ...data,
              structure.map((column: any, index: number) => {
                if (index === 0) return "SUM";
                if (column.key === "date") return "SUM";
                if (column.key === "userCount") return reports?.data[0]?.userCount;
                if (
                  [
                    "totalBalanceUSD",
                    user.userRole === "AGENT" ? "userBalanceMega" : "userBalanceGp2",
                    "totalHotWallet",
                    "fiatHotWallet",
                    "hotWalletUsdt",
                  ].includes(column?.key)
                )
                  return reports?.data[0][column.key]
                    ? NumberUtils.toFormatNumber(+reports?.data[0][column.key], +getEnv("NUMBER_DECIMAL_DISPLAY"))
                    : `0.00000000`;
                if (
                  [
                    "orderVolume",
                    "profitVolume",
                    "netProfit",
                    // "refundValue",
                    "exchangeIn",
                    "exchangeOut",
                    "depositUsdt",
                    "depositUsdtBep20",
                    "depositUsdtTrc20",
                    "withdrawUsdt",
                    "withdrawUsdtBep20",
                    "withdrawUsdtTrc20",
                    "exchangeOutFeeTotal",
                    // "convertRecieved",
                    "transferUsdt",
                    "recoveryUsdt",
                    "teamCommission",
                    "withdrawFiat",
                    "depositFiat",
                    "mktVolume",
                    "mktProfit",
                    "agentCommission",
                    "refundValue",
                    "depositBonus",
                    "lose7",
                    "lose8",
                  ].includes(column?.key)
                )
                  return reports.sumReports[column.key]
                    ? NumberUtils.toFormatNumber(+reports.sumReports[column.key], +getEnv("NUMBER_DECIMAL_DISPLAY"))
                    : `0.00000000`;
                return reports.sumReports[column.key] || "0";
              }),
            ];

            const ws = XLSX.utils.aoa_to_sheet(dataExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

            const now = new Date();
            XLSX.writeFile(wb, `System report ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
              type: "binary",
            });

            resolve(
              CreateAlert({
                type: "success",
                message: "Export data success.",
              })
            );
          } catch (error) {
            resolve(
              CreateAlert({
                type: "danger",
                // @ts-ignore
                message: error.message,
              })
            );
          }
        });
      };

      return (
        <section className="box">
          <div className="title">System report</div>

          <SystemReportDetailPopup detail={systemReportDetail} onClose={() => setSystemReportDetail(null)} />

          <div className="SystemReport">
            <div className="SystemReport__Head">
              <div className="item">
                <div className="label">Range time</div>
                <InputDateTimeRange
                  onChange={(e) => setRangeTime(e)}
                  startTimeDefaultValue={rangeTime?.startTime}
                  endTimeDefaultValue={rangeTime?.endTime}
                  format={"dd/MM/y"}
                />
              </div>
              <div className="item">
                <div className="label">Mode</div>
                <InputSelect options={ModeOptions} onChange={(e) => setModeOption(e)} onTouched={() => false} value={modeOption} name="" />
              </div>

              <div className="item">
                <div className="label">Export</div>
                <Button
                  label="Excel"
                  buttonType="success"
                  disabled={!reports || (reports.data && reports.data.length === 0)}
                  onClick={handleExportExcel}
                />
              </div>
            </div>

            {(() => {
              if (reports && !reports.data) return <Message type="emptyData" message={moduleConfig.translate("no-data")} />;
              if (!rangeTime) return <Message type="info" message="You have to select range time to export report" />;
              if (!reports) return <Message type="loading" />;
              if (reports.error) return <Message type="error" {...reports.error} />;

              return (
                <Table
                  doubleScroll
                  hasOrderColumn
                  className="overload hideFooter"
                  structure={structure}
                  fetchData={async (params) => {
                    let data = [...reports.data];

                    if (params["date"] === "increase") data = data.sort((a: any, b: any) => a.start - b.start);
                    if (params["date"] === "descrease") data = data.sort((a: any, b: any) => b.start - a.start);

                    if (params["orderVolume"] === "increase") data = data.sort((a: any, b: any) => a.orderVolume - b.orderVolume);
                    if (params["orderVolume"] === "descrease") data = data.sort((a: any, b: any) => b.orderVolume - a.orderVolume);

                    if (params["userCount"] === "increase") data = data.sort((a: any, b: any) => a.userCount - b.userCount);
                    if (params["userCount"] === "descrease") data = data.sort((a: any, b: any) => b.userCount - a.userCount);

                    if (params["userOrderCount"] === "increase") data = data.sort((a: any, b: any) => a.userOrderCount - b.userOrderCount);
                    if (params["userOrderCount"] === "descrease") data = data.sort((a: any, b: any) => b.userOrderCount - a.userOrderCount);

                    if (params["profitVolume"] === "increase") data = data.sort((a: any, b: any) => a.profitVolume - b.profitVolume);
                    if (params["profitVolume"] === "descrease") data = data.sort((a: any, b: any) => b.profitVolume - a.profitVolume);

                    if (params["netProfit"] === "increase") data = data.sort((a: any, b: any) => a.netProfit - b.netProfit);
                    if (params["netProfit"] === "descrease") data = data.sort((a: any, b: any) => b.netProfit - a.netProfit);

                    if (params["mktVolume"] === "increase") data = data.sort((a: any, b: any) => a.mktVolume - b.mktVolume);
                    if (params["mktVolume"] === "descrease") data = data.sort((a: any, b: any) => b.mktVolume - a.mktVolume);

                    if (params["mktProfit"] === "increase") data = data.sort((a: any, b: any) => a.mktProfit - b.mktProfit);
                    if (params["mktProfit"] === "descrease") data = data.sort((a: any, b: any) => b.mktProfit - a.mktProfit);

                    if (params["userBalanceUsdt"] === "increase") data = data.sort((a: any, b: any) => a.userBalanceUsdt - b.userBalanceUsdt);
                    if (params["userBalanceUsdt"] === "descrease") data = data.sort((a: any, b: any) => b.userBalanceUsdt - a.userBalanceUsdt);

                    if (params["userBalanceGp2"] === "increase") data = data.sort((a: any, b: any) => a.userBalanceGp2 - b.userBalanceGp2);
                    if (params["userBalanceGp2"] === "descrease") data = data.sort((a: any, b: any) => b.userBalanceGp2 - a.userBalanceGp2);

                    if (params["userBalanceMega"] === "increase") data = data.sort((a: any, b: any) => a.userBalanceMega - b.userBalanceMega);
                    if (params["userBalanceMega"] === "descrease") data = data.sort((a: any, b: any) => b.userBalanceMega - a.userBalanceMega);

                    if (params["exchangeIn"] === "increase") data = data.sort((a: any, b: any) => a.exchangeIn - b.exchangeIn);
                    if (params["exchangeIn"] === "descrease") data = data.sort((a: any, b: any) => b.exchangeIn - a.exchangeIn);

                    if (params["exchangeOut"] === "increase") data = data.sort((a: any, b: any) => a.exchangeOut - b.exchangeOut);
                    if (params["exchangeOutFeeTotal"] === "descrease") data = data.sort((a: any, b: any) => b.exchangeOut - a.exchangeOut);

                    if (params["exchangeOutFeeTotal"] === "increase")
                      data = data.sort((a: any, b: any) => a.exchangeOutFeeTotal - b.exchangeOutFeeTotal);
                    if (params["exchangeOutFeeTotal"] === "descrease")
                      data = data.sort((a: any, b: any) => b.exchangeOutFeeTotal - a.exchangeOutFeeTotal);

                    if (params["hotWalletUsdt"] === "increase") data = data.sort((a: any, b: any) => a.hotWalletUsdt - b.hotWalletUsdt);
                    if (params["hotWalletUsdt"] === "descrease") data = data.sort((a: any, b: any) => b.hotWalletUsdt - a.hotWalletUsdt);

                    if (params["hotWalletGp2"] === "increase") data = data.sort((a: any, b: any) => a.hotWalletGp2 - b.hotWalletGp2);
                    if (params["hotWalletGp2"] === "descrease") data = data.sort((a: any, b: any) => b.hotWalletGp2 - a.hotWalletGp2);

                    // if (params["refundValue"] === "increase") data = data.sort((a: any, b: any) => a.refundValue - b.refundValue);
                    // if (params["refundValue"] === "descrease") data = data.sort((a: any, b: any) => b.refundValue - a.refundValue);

                    if (params["orderCount"] === "increase") data = data.sort((a: any, b: any) => a.orderCount - b.orderCount);
                    if (params["orderCount"] === "descrease") data = data.sort((a: any, b: any) => b.orderCount - a.orderCount);

                    if (params["loginCount"] === "increase") data = data.sort((a: any, b: any) => a.loginCount - b.loginCount);
                    if (params["loginCount"] === "descrease") data = data.sort((a: any, b: any) => b.loginCount - a.loginCount);

                    if (params["transferUsdt"] === "increase") data = data.sort((a: any, b: any) => a.transferUsdt - b.transferUsdt);
                    if (params["transferUsdt"] === "descrease") data = data.sort((a: any, b: any) => b.transferUsdt - a.transferUsdt);

                    if (params["recoveryUsdt"] === "increase") data = data.sort((a: any, b: any) => a.recoveryUsdt - b.recoveryUsdt);
                    if (params["recoveryUsdt"] === "descrease") data = data.sort((a: any, b: any) => b.recoveryUsdt - a.recoveryUsdt);

                    if (params["totalHotWallet"] === "increase") data = data.sort((a: any, b: any) => a.totalHotWallet - b.totalHotWallet);
                    if (params["totalHotWallet"] === "descrease") data = data.sort((a: any, b: any) => b.totalHotWallet - a.totalHotWallet);

                    if (params["fiatHotWallet"] === "increase") data = data.sort((a: any, b: any) => a.fiatHotWallet - b.fiatHotWallet);
                    if (params["fiatHotWallet"] === "descrease") data = data.sort((a: any, b: any) => b.fiatHotWallet - a.fiatHotWallet);

                    if (params["depositUsdt"] === "increase") data = data.sort((a: any, b: any) => a.depositUsdt - b.depositUsdt);
                    if (params["depositUsdt"] === "descrease") data = data.sort((a: any, b: any) => b.depositUsdt - a.depositUsdt);

                    if (params["depositUsdtBep20"] === "increase") data = data.sort((a: any, b: any) => a.depositUsdtBep20 - b.depositUsdtBep20);
                    if (params["depositUsdtBep20"] === "descrease") data = data.sort((a: any, b: any) => b.depositUsdtBep20 - a.depositUsdtBep20);

                    if (params["depositUsdtTrc20"] === "increase") data = data.sort((a: any, b: any) => a.depositUsdtTrc20 - b.depositUsdtTrc20);
                    if (params["depositUsdtTrc20"] === "descrease") data = data.sort((a: any, b: any) => b.depositUsdtTrc20 - a.depositUsdtTrc20);

                    if (params["withdrawUsdt"] === "increase") data = data.sort((a: any, b: any) => a.withdrawUsdt - b.withdrawUsdt);
                    if (params["withdrawUsdt"] === "descrease") data = data.sort((a: any, b: any) => b.withdrawUsdt - a.withdrawUsdt);

                    if (params["withdrawUsdtBep20"] === "increase") data = data.sort((a: any, b: any) => a.withdrawUsdtBep20 - b.withdrawUsdtBep20);
                    if (params["withdrawUsdtBep20"] === "descrease") data = data.sort((a: any, b: any) => b.withdrawUsdtBep20 - a.withdrawUsdtBep20);

                    if (params["withdrawUsdtTrc20"] === "increase") data = data.sort((a: any, b: any) => a.withdrawUsdtTrc20 - b.withdrawUsdtTrc20);
                    if (params["withdrawUsdtTrc20"] === "descrease") data = data.sort((a: any, b: any) => b.withdrawUsdtTrc20 - a.withdrawUsdtTrc20);

                    if (params["teamCommission"] === "increase") data = data.sort((a: any, b: any) => a.teamCommission - b.teamCommission);
                    if (params["teamCommission"] === "descrease") data = data.sort((a: any, b: any) => b.teamCommission - a.teamCommission);

                    if (params["agentCommission"] === "increase") data = data.sort((a: any, b: any) => a.agentCommission - b.agentCommission);
                    if (params["agentCommission"] === "descrease") data = data.sort((a: any, b: any) => b.agentCommission - a.agentCommission);

                    if (params["refundValue"] === "increase") data = data.sort((a: any, b: any) => a.refundValue - b.refundValue);
                    if (params["refundValue"] === "descrease") data = data.sort((a: any, b: any) => b.refundValue - a.refundValue);

                    if (params["withdrawFiat"] === "increase") data = data.sort((a: any, b: any) => a.withdrawFiat - b.withdrawFiat);
                    if (params["withdrawFiat"] === "descrease") data = data.sort((a: any, b: any) => b.withdrawFiat - a.withdrawFiat);

                    if (params["depositFiat"] === "increase") data = data.sort((a: any, b: any) => a.depositFiat - b.depositFiat);
                    if (params["depositFiat"] === "descrease") data = data.sort((a: any, b: any) => b.depositFiat - a.depositFiat);

                    if (params["depositBonus"] === "increase") data = data.sort((a: any, b: any) => a.depositBonus - b.depositBonus);
                    if (params["depositBonus"] === "descrease") data = data.sort((a: any, b: any) => b.depositBonus - a.depositBonus);

                    if (params["lose7"] === "increase") data = data.sort((a: any, b: any) => a.lose7 - b.lose7);
                    if (params["lose7"] === "descrease") data = data.sort((a: any, b: any) => b.lose7 - a.lose7);

                    if (params["lose8"] === "increase") data = data.sort((a: any, b: any) => a.lose8 - b.lose8);
                    if (params["lose8"] === "descrease") data = data.sort((a: any, b: any) => b.lose8 - a.lose8);

                    return {
                      data: [{ isSum: true }, ...data],
                      count: data.length,
                    };
                  }}
                />
              );
            })()}
          </div>
        </section>
      );
    },
    () => true
  )
);

export const SystemReportDetailPopup: FC<{
  detail: any;
  onClose: () => void;
}> = (props) => {
  if (!props.detail) return null;

  const item = props.detail;

  const data = [
    {
      label: "Start",
      value: `${new Date(item.start).toLocaleDateString(getLocaleKey(), {
        hour12: false,
      })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
        hour12: false,
      })}`,
    },
    {
      label: "End",
      value: `${new Date(item.end).toLocaleDateString(getLocaleKey(), {
        hour12: false,
      })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
        hour12: false,
      })}`,
    },
    {
      label: "Modified",
      value: `${new Date(item.modified).toLocaleDateString(getLocaleKey(), {
        hour12: false,
      })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
        hour12: false,
      })}`,
    },
    {
      label: "Total won/lose volume",
      value: `${(+item.wonVolume).toLocaleString(getLocaleKey(), {
        maximumFractionDigits: 2,
      })}/${(+item.lostVolume).toLocaleString(getLocaleKey(), {
        maximumFractionDigits: 2,
      })}`,
    },
    {
      label: "Hot Wallet",
      value: (
        <span>
          GPO:{" "}
          {(+item.hotWalletGp2).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}{" "}
          <br />
          USDT:{" "}
          {(+item.hotWalletUsdt).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </span>
      ),
    },
    // {
    //     label: 'Deposit', value: <span>
    //         ETH: {(+item.depositEth).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })} <br />
    //         USDT: {(+item.depositUsdt).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
    //     </span>
    // },
    // {
    //   label: "Withdraw",
    //   value: (
    //     <span>
    //       GPO:{" "}
    //       {(+item.withdrawGp2).toLocaleString(getLocaleKey(), {
    //         maximumFractionDigits: 2,
    //         minimumFractionDigits: 2,
    //       })}{" "}
    //       <br />
    //       USDT:{" "}
    //       {(+item.withdrawUsdt).toLocaleString(getLocaleKey(), {
    //         maximumFractionDigits: 2,
    //         minimumFractionDigits: 2,
    //       })}
    //     </span>
    //   ),
    // },
    // {
    //   label: "User 's Balance",
    //   value: (
    //     <span>
    //       MEGADAO:{" "}
    //       {(+item.userBalanceGp2).toLocaleString(getLocaleKey(), {
    //         maximumFractionDigits: 2,
    //         minimumFractionDigits: 2,
    //       })}{" "}
    //       <br />
    //       {/* USD: {(+item.userBalanceUsd).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })} <br /> */}
    //       USDT:{" "}
    //       {(+item.userBalanceUsdt).toLocaleString(getLocaleKey(), {
    //         maximumFractionDigits: 2,
    //         minimumFractionDigits: 2,
    //       })}{" "}
    //       <br />
    //       {/* BUT: {(+item.userBalanceBut).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })} */}
    //     </span>
    //   ),
    // },
    // {
    //   label: "Safe",
    //   value: (
    //     <span>
    //       GPO:{" "}
    //       {(+item.safeGp2).toLocaleString(getLocaleKey(), {
    //         maximumFractionDigits: 2,
    //         minimumFractionDigits: 2,
    //       })}{" "}
    //       <br />
    //       USDT:{" "}
    //       {(+item.safeUsdt).toLocaleString(getLocaleKey(), {
    //         maximumFractionDigits: 2,
    //         minimumFractionDigits: 2,
    //       })}
    //     </span>
    //   ),
    // },
    {
      label: "Login Count",
      key: "loginCount",
      value: item.loginCount,
    },
    {
      label: "Direct Commission",
      key: "directCommission",
      value: item.directCommission,
    },
    {
      label: "CashBack",
      key: "cashBack",
      value: (+item.cashBack).toLocaleString(getLocaleKey(), {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    },
    {
      label: "New Package",
      key: "newPackage",
      value: item.newPackage,
    },
    {
      label: "Trading Commission",
      key: "rankCommission",
      value: item.rankCommission,
    },
  ];

  return (
    <div
      className="SystemReport__DetailPopup"
      id="SystemReport__DetailPopup"
      onClick={(e: any) => (e.target.id === "SystemReport__DetailPopup" ? props.onClose() : null)}
    >
      <div className="box">
        <div className="boxTitle">
          <span>Detail Report</span>
          <div className="btnClose" onClick={() => props.onClose()}>
            <Icon.Close />
          </div>
        </div>
        <div className="content">
          <table>
            <tbody>
              {data.map((row, key) => (
                <tr key={key}>
                  <td>{row.label}</td>
                  <td>{row.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
