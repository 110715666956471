export function unflatTreeArr(flat: any[]) {
    // Create root for top-level node(s)
    const root: any[] = [];
    // Cache found parent index
    const map: any = {};

    flat.forEach(node => {
        // No parentId means top level
        try {
            if (!node.parentId) return root.push(node);

            // Insert node as child of parent in flat array
            let parentIndex = map[node.parentId];

            if (typeof parentIndex !== "number") {
                parentIndex = flat.findIndex(el => el.id === node.parentId);
                map[node.parentId] = parentIndex;
            }

            if (!flat[parentIndex]) return root.push({ ...node, children: [] });

            if (!flat[parentIndex].children) {
                return flat[parentIndex].children = [node];
            }

            flat[parentIndex].children.push(node);
        } catch (error) {
            console.log(node, error)
        }
    });

    return root;
}