import React, { FC } from 'react'

import { Table } from '../../../modules'
import { AdminService } from '../../../services/admin'

export const PageKYC: FC = () => {
    return (
        <div className="PageKYC">
            <div className="pageTitle">List Pending KYC</div>

            <Table
                structure={[
                    {
                        name: 'Email',
                        key: 'email',
                    },
                    {
                        name: 'First Name',
                        key: 'firstName'
                    },
                    {
                        name: 'Last Name',
                        key: 'lastName'
                    },
                    {
                        name: 'Avatar',
                        key: 'avartarUrl'
                    },
                    {
                        name: 'Back of card',
                        key: 'backOfCardUrl'
                    },
                    {
                        name: 'Front of card',
                        key: 'frontOfCardUrl'
                    },
                    {
                        name: 'Actions',
                        key: 'frontOfCardUrl'
                    }, 
                ]}
                fetchData={async () => AdminService.getPendingKYC()}
            />
        </div>
    )
}