import React, { FC } from 'react'
import { translate } from '../../../../languages';
import { ClassNames } from '../../../../modules';
import { Sentiments } from '../../../../components/sentiments';

export const ResultsPanel: FC<{ last100Reports: any, droplets: any[] }> = (props) => {
    const deviceType: any = 'Desktop';
    const { countUp, countDown, countBalance } = props.last100Reports;

    return (
        <div className="ResultsPanel">
            <div className="head">
                <div className="content">
                    <div className="title">{translate('last-count-results', { count: countUp + countDown + countBalance })}</div>
                    {countUp ? <div className="boxCount up">{countUp} {translate('up').toUpperCase()}</div> : null}
                    {countDown ? <div className="boxCount down">{countDown} {translate('down').toUpperCase()}</div> : null}
                    {countBalance ? <div className="boxCount balance">{countBalance} {translate('candle-balance').toUpperCase()}</div> : null}
                </div>

                {/* {deviceType === 'Mobile' ? <div className="toggleView">
                    <div
                        className={ClassNames({ item: true, active: viewType === 'droplets' })}
                        onClick={() => setViewType('droplets')}
                    >
                        {translate('roplets')}
                    </div>

                    <div
                        className={ClassNames({ item: true, active: viewType === 'necklaces' })}
                        onClick={() => setViewType('necklaces')}
                    >
                        {translate('necklaces')}
                    </div>
                </div> : null} */}
            </div>

            {(() => {
                if (deviceType === 'Mobile') {
                    return <div className="rowBox">
                        <div className="resultRow">
                            <div className="boxResults">
                                {props.droplets.map((dots: any[], columnKey: number) => {
                                    return (
                                        <div className="column" key={columnKey}>
                                            {dots.map((dot, keyDot) => {
                                                return (
                                                    <div
                                                        key={keyDot}
                                                        className={ClassNames({
                                                            dot: true,
                                                            [dot.type as string]: !!dot.type,
                                                            isCurrent: !!dot.isCurrent
                                                        })}
                                                    />
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <Sentiments />
                    </div>
                }

                return <div className="resultRow">
                    <div className="boxResults">
                        {props.droplets.map((dots: any[], columnKey: number) => {
                            return (
                                <div className="column" key={columnKey}>
                                    {dots.map((dot, keyDot) => {
                                        return (
                                            <div
                                                key={keyDot}
                                                className={ClassNames({
                                                    dot: true,
                                                    [dot.type as string]: !!dot.type,
                                                    isCurrent: !!dot.isCurrent
                                                })}
                                            />
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            })()}
        </div>
    )
}