import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Routes } from "../../../../AppRoutes";
import { InputDateTime, InputText, InputTextArea } from "../../../../components";
import { InputImageSingle } from "../../../../components/single-image";
import { Tabs } from "../../../../components/tabs";
import { translate } from "../../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../../modules";
import { ConfigService } from "../../../../services/config/config.service";
import { useSelector } from "../../../../store";
import { EditorForm } from "../editor";

export const EditNewsPage: FC = (props) => {
  const { push } = useHistory();
  const detailNews = useSelector((state) => state.detailNews);
  const { newsId, title, content, image, expirationDate } = detailNews;

  const [newContent, setNewContent] = useState<any>({
    "en-US": "",
    "vi-VN": "",
    "ko-KR": "",
    "zh-CN": "",
    "my-MM": "",
  });

  useEffect(() => {
    if (!detailNews) push(Routes.News.path);
    setNewContent(() => {
      return content;
    });
  }, [content, detailNews, push]);

  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "newsId",
        defaultValue: newsId,
        isDisabled: true,
      },
      {
        name: "titleEN",
        defaultValue: title?.["en-US"],
        validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "titleVI",
        defaultValue: title?.["vi-VN"],
        // validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "titleKO",
        defaultValue: title?.["ko-KR"],
        // validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "titleZH",
        defaultValue: title?.["zh-CN"],
        // validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "titleMM",
        defaultValue: title?.["my-MM"],
        // validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "image",
        defaultValue: { file: { name: image }, src: image },
        validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "expirationDate",
        defaultValue: expirationDate,
        validate: Yup.string().required(translate("must-be-provided")),
      },
    ],
    onSubmit: async (values) => {
      const { newsId, titleEN, titleVI, titleKO, titleZH, titleMM, image, expirationDate } = values;
      return ConfigService.editNews(newsId, {
        title: {
          "en-US": titleEN,
          "vi-VN": titleVI,
          "ko-KR": titleKO,
          "zh-CN": titleZH,
          "my-MM": titleMM,
        },
        content: newContent,
        image: image?.src,
        expirationDate: moment(expirationDate).toISOString(),
      })
        .then(() => {
          CreateAlert({
            message: "Update success.",
            type: "success",
          });
          push(Routes.News.path);
        })
        .catch((error: any) => {
          CreateAlert({
            message: translate(error.message),
            type: "danger",
          });
        });
    },
  });

  return (
    <>
      <h1
        className="mb40"
        style={{
          fontSize: "1.5rem",
          fontWeight: "lighter",
        }}
      >
        Edit News
      </h1>
      <form className="Edit" onSubmit={handleSubmit}>
        <InputWraper label="News ID" inputProps={getInputProps("newsId")} component={InputText} />
        <Tabs
          tabs={[
            {
              label: "English",
              id: "titleEN",
              subId: "en-US",
            },
            {
              label: "Vietnamese",
              id: "titleVI",
              subId: "vi-VN",
            },
            {
              label: "Korean",
              id: "titleKO",
              subId: "ko-KR",
            },
            {
              label: "Chinese",
              id: "titleZH",
              subId: "zh-CN",
            },
            {
              label: "Myanmar",
              id: "titleMM",
              subId: "my-MM",
            },
          ]}
          render={(tab) => {
            return (
              <>
                <InputWraper label={"Title"} inputProps={getInputProps(tab.id)} component={InputText} />
                <EditorForm
                  title="Content"
                  dataDefault={newContent[`${tab.subId}`]}
                  getData={(data: any) => {
                    setNewContent((state: any) => {
                      state[`${tab.subId}`] = data;
                      return state;
                    });
                  }}
                />
              </>
            );
          }}
        ></Tabs>

        <InputWraper label={"Image"} inputProps={getInputProps("image")} component={InputImageSingle} className="mr5" />
        <InputWraper label={translate("Expiration Date")} inputProps={getInputProps("expirationDate")} component={InputDateTime} className="ml5" />
        <div>
          <Button
            label="Update"
            isMiddle
            buttonType="success"
            className="btnBan"
            type="submit"
            disabled={newContent["en-US"] === ""}
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </>
  );
};
