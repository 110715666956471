import React, { FC } from 'react'
import { RouteComponentProps } from 'react-router-dom'

export interface IReportWraperProps extends RouteComponentProps {

}

export const withReportWraper = (Component: FC<IReportWraperProps>) => (props: IReportWraperProps) => {
    return (
        <div className="withReportWraper">
            {/* <div className="TabLinks">
                <NavLink to={Routes.transactionList.path} exact={true}>
                    <Icon.Exchange />

                    Transactions
                </NavLink>

                <NavLink to={Routes.withdraw.path} exact={true}>
                    <Icon.KYC />

                    Withdraw Progress
                </NavLink>
            </div> */}

            <Component {...props} />
        </div>
    )
}