const defaultState = null

export const SET_COINS = 'SET_COINS';

export const coinsReducer = (state = defaultState, action: any) => {
    const { type, data, error } = action;
    if (type === SET_COINS) {
        if (error) return { error }
        if (data) return data;
    }
    return state;
}